import { Status } from '../../util/api/pc'
import styles from './statusIndicator.styl'
import { StatusIcon } from '@nike/eds'
import classnames from 'classnames'

interface StatusIndicatorProps {
  status: Status
  className?: string
}

const statusMap = {
  pass: 'success',
  warn: 'warning',
  fail: 'error',
  undetermined: 'info',
  maintenance: 'neutral',
} as const

export const StatusIndicator = ({ status, className = '' }: StatusIndicatorProps): JSX.Element => (
  <StatusIcon
    aria-hidden='true'
    className={classnames(styles.statusIcon, className)}
    statusIconSize='s'
    status={statusMap[status]}
  />
)
