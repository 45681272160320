import { UptimeRegion } from '../util/api/pc'
import { MAPPED_REGIONS } from '../util/regions'
import { Text, Tooltip } from '@nike/eds'

const toFixed = (value: number) => +value.toFixed(2) // convert the value to a number with two decimal places precision

const overallUptime = (regions: UptimeRegion[]): string => {
  let totalUptime = 0
  regions.forEach(({ value }) => (totalUptime += value))
  return toFixed(totalUptime / regions.length).toString()
}

interface UptimeRegionsProps {
  innerKey: string
  regions?: UptimeRegion[] | null
}

const UptimeRegions = ({ regions, innerKey }: UptimeRegionsProps) => (
  <div>
    {regions?.map(({ region, value }) => (
      <div key={`uptime-${innerKey}-${region}`}>
        <Text color='white'>
          <span>{MAPPED_REGIONS[region] || region}</span>: <span>{toFixed(value)}%</span>
        </Text>
      </div>
    ))}
  </div>
)

export const UptimeDetails = ({ regions, innerKey }: UptimeRegionsProps) => {
  if (!regions || regions?.length === 0) {
    return <>N/A</>
  }

  return (
    <Tooltip
      isDark
      bodySlot={<UptimeRegions regions={regions} innerKey={innerKey} />}
      placement={'right'}
    >
      <span>{overallUptime(regions)}%</span>
    </Tooltip>
  )
}
