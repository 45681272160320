import { ConsoleApiError } from '../util/api/pc'
import { NarkContext } from '../util/nark'
import { useContext } from 'react'

const defaultNarkValues = {
  source: 'ui',
  operation: 'putUserAccessRequest',
  feature: 'techSolution.oneClickAccess',
}

type NarkLogError = (errors: unknown | ConsoleApiError[], operation?: string) => void

export const useUserAccessNarkLog = (
  techSolutionId: string,
  accessType: string
): { nark: NarkLogError } => {
  const nark = useContext(NarkContext)

  const narkLogError: NarkLogError = (errors, operation) => {
    nark.logException({
      ...defaultNarkValues,
      operation: operation || defaultNarkValues.operation,
      data: {
        techSolutionId,
        error: errors,
        name: accessType,
      },
    })
  }

  return { nark: narkLogError }
}
