import { Link } from '../../components/Link'
import { SnowFormType } from '../../util/api/pc'
import { UserAccessContext } from '../UserAccessContext'
import { TEXT_LABELS, SNOW_FORM_TYPE } from '../consts'
import { SnowTicketStatus, SnowTicketStatusProps } from './SnowTicketStatus'
import { Button } from '@nike/eds'
import { useContext } from 'react'

export const Snow = ({
  accessToken,
  techSolutionId,
}: SnowTicketStatusProps): JSX.Element | null => {
  const { snow: snowSetting } = useContext(UserAccessContext)

  if (!snowSetting) {
    return null
  }

  const formType: SnowFormType = snowSetting.formType

  if (formType === SNOW_FORM_TYPE.link && snowSetting?.externalLink) {
    return (
      <Link href={snowSetting?.externalLink}>
        <Button tabIndex={-1} size='small'>
          {TEXT_LABELS.requestSnow}
        </Button>
      </Link>
    )
  }

  return <SnowTicketStatus accessToken={accessToken} techSolutionId={techSolutionId} />
}
