// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k4G98AVpAI0kKL7lYNxe {
  width: 100%;
}
.k4G98AVpAI0kKL7lYNxe .eds-table-cell {
  min-width: 200px;
  vertical-align: top;
}
.k4G98AVpAI0kKL7lYNxe .eds-table-cell__content,
.k4G98AVpAI0kKL7lYNxe .eds-table-cell__content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
.k4G98AVpAI0kKL7lYNxe .iySzgDG56Du3rMn2beSs {
  max-width: 500px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: none;
}
.k4G98AVpAI0kKL7lYNxe .vDfjteCdbsp8cpxhX9zX {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: wrap;
}
.k4G98AVpAI0kKL7lYNxe ._974uyTVGpGRhrfS02gwl {
  min-width: 50px;
}
.k4G98AVpAI0kKL7lYNxe .BKoVb4rS5u1VtEjQY4Ua {
  color: var(--eds-color-background-success);
}
.k4G98AVpAI0kKL7lYNxe .BKoVb4rS5u1VtEjQY4Ua button {
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/HomePage/tabComponents/resourceTable.module.styl"],"names":[],"mappings":"AAAA;EACE,WAAO;AACT;AACE;EACE,gBAAW;EACX,mBAAgB;AACpB;AAAE;;EACE,oBAAS;EACT,qBAAoB;EACpB,4BAAoB;EACpB,sBAAY;AAGhB;AADE;EACE,gBAAW;EACX,oBAAS;EACT,4BAAoB;EACpB,wBAAoB;AAGxB;AADE;EACE,gBAAW;EACX,uBAAe;EACf,gBAAU;EACV,iBAAa;AAGjB;AADE;EACE,eAAW;AAGf;AADE;EACE,0CAAO;AAGX;AADI;EACE,iBAAa;AAGnB","sourcesContent":[".resourcesTable\n  width: 100%\n\n  :global(.eds-table-cell)\n    min-width: 200px\n    vertical-align: top; \n  :global(.eds-table-cell__content), :global(.eds-table-cell__content p)\n    display: -webkit-box\n    -webkit-line-clamp: 3\n    -webkit-box-orient: vertical\n    word-break: break-word\n\n  .descriptionTooltip\n    max-width: 500px\n    display: -webkit-box\n    -webkit-box-orient: vertical\n    -webkit-line-clamp: none\n\n  .linkCell\n    max-width: 300px\n    text-overflow: ellipsis\n    overflow: hidden\n    white-space: wrap\n\n  .copyCell\n    min-width: 50px\n\n  .copyIcon \n    color: var(--eds-color-background-success)\n\n    button\n      margin-left: 20px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resourcesTable": `k4G98AVpAI0kKL7lYNxe`,
	"descriptionTooltip": `iySzgDG56Du3rMn2beSs`,
	"linkCell": `vDfjteCdbsp8cpxhX9zX`,
	"copyCell": `_974uyTVGpGRhrfS02gwl`,
	"copyIcon": `BKoVb4rS5u1VtEjQY4Ua`
};
export default ___CSS_LOADER_EXPORT___;
