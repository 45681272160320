import { client } from './client'

export interface Team {
  teamName: string
  isAdmin: boolean
  teamId: string
  username: string
}

interface GetTeamsResponse {
  items?: Team[]
  message?: string
}

export const getTeams = async (teamsApiUrl: string, accessToken: string): Promise<Team[]> => {
  const { items, message } = await client.get<GetTeamsResponse>(teamsApiUrl, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  if (message) {
    console.error(`Error fetching teams: ${message}`)
  }
  return items || []
}
