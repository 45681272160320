import { ControlTypes, Option, Priority } from './types'

export const FieldsSelect: Option<ControlTypes>[] = [
  {
    label: 'Text: single line',
    value: ControlTypes.SINGLE_LINE,
  },
  {
    label: 'Text: multi line',
    value: ControlTypes.MULTI_LINE,
  },
  {
    label: 'Text: numerical',
    value: ControlTypes.NUMBER,
  },
  {
    label: 'Text: email',
    value: ControlTypes.EMAIL,
  },
  {
    label: 'Select: multiple emails',
    value: ControlTypes.MULTI_EMAIL,
  },
  {
    label: 'Text: url',
    value: ControlTypes.URL,
  },
  {
    label: 'Select',
    value: ControlTypes.SELECT,
  },
  {
    label: 'Checkbox',
    value: ControlTypes.CHECKBOX,
  },
  {
    label: 'Toggle',
    value: ControlTypes.TOGGLE,
  },
]

export const PRIORITY_OPTIONS = [
  {
    label: Priority.CRITICAL,
    value: Priority.CRITICAL,
  },
  {
    label: Priority.MAJOR,
    value: Priority.MAJOR,
  },
  {
    label: Priority.NORMAL,
    value: Priority.NORMAL,
  },
  {
    label: Priority.MINOR,
    value: Priority.MINOR,
  },
]
