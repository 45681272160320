import { RenderFieldProps } from './types'
import { validation } from './validation'
import { Radio as EdsRadio, RadioGroup } from '@nike/eds'
import { Controller, useWatch } from 'react-hook-form'

export const Radio = ({ config, control }: RenderFieldProps): JSX.Element => {
  const selectedValue = useWatch({ control, name: config.title })

  return (
    <Controller
      name={config.title}
      control={control}
      rules={validation(config.type, config.required, config.title)}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <RadioGroup
          id={config.title}
          label={config.title}
          name={config.title}
          defaultValue={config.defaultValue || ''}
          hasErrors={!!error}
          errorMessage={error?.message}
          valueSelected={selectedValue as string}
          subtitle={config?.subtitle}
          onChange={onChange}
        >
          {config.options.map((option) => (
            <EdsRadio
              label={option.label}
              value={option.value}
              id={`${config.title} - ${option.value}`}
              key={`${config.title} - ${option.value}`}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}
