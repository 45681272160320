import { Status } from './Status'
import { UserAccessContext } from './UserAccessContext'
import styles from './accessRequest.styl'
import { TEXT_LABELS } from './consts'
import { Spinner, Text, Divider } from '@nike/eds'
import { useContext } from 'react'

export interface StatusDetailsProps {
  role?: string
}

export const StatusDetails = ({ role }: StatusDetailsProps) => {
  const { accessMethodsCount, idLocker, jira, snow, statuses } = useContext(UserAccessContext)

  if (accessMethodsCount <= 1) return null

  return (
    <>
      <Text font='body-3'>{TEXT_LABELS.statusesDescription(accessMethodsCount)}</Text>
      <Divider />
      {idLocker && role && (
        <div className={styles.accessContainer}>
          <Text font='body-3' className={styles.roleName}>
            {TEXT_LABELS.idLocker(role)}:&nbsp;
          </Text>
          {statuses.idLocker?.[role] ? (
            <Status
              status={statuses.idLocker?.[role]}
              isAccessRequest={false}
              disableRequesting
              disableStatusModal
              displayInfo
            />
          ) : (
            <Spinner />
          )}
        </div>
      )}
      {jira && (
        <div className={styles.accessContainer}>
          <Text font='body-3' className={styles.roleName}>
            {TEXT_LABELS.jira}:&nbsp;
          </Text>
          {statuses.jira ? (
            <Status
              status={statuses.jira}
              isAccessRequest={false}
              disableRequesting
              disableStatusModal
              displayInfo
              disclaimer={TEXT_LABELS.ticketStatusDisclaimer}
            />
          ) : (
            <Spinner />
          )}
        </div>
      )}
      {snow && (
        <div className={styles.accessContainer}>
          <Text font='body-3' className={styles.roleName}>
            {TEXT_LABELS.snow}:&nbsp;
          </Text>
          {statuses.snow ? (
            <Status
              status={statuses.snow}
              isAccessRequest={false}
              disableRequesting
              disableStatusModal
              displayInfo
              disclaimer={TEXT_LABELS.ticketStatusDisclaimer}
            />
          ) : (
            <Spinner />
          )}
        </div>
      )}
    </>
  )
}
