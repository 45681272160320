import styles from './errorMessage.styl'
import { Icon, Link } from '@nike/eds'

interface ErrorMessageProps {
  name: string
  slackURL?: string
}

export const ErrorMessage = ({ slackURL, name }: ErrorMessageProps): JSX.Element => (
  <div className={styles.errorWrapper}>
    <h1 className={styles.heading}>Oops...something isn&apos;t quite right</h1>
    <p className={styles.text}>
      An issue occurred with the tech solution, please refresh the page. If the error happens again,
      please contact {slackURL ? <Link href={slackURL}>#{name}</Link> : <>{name} team</>}.
    </p>
    <Icon size='l' className={styles.swoosh} name='NikeSwoosh' />
  </div>
)
