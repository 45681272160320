import { getConfig } from '../../config'
import { Template } from '../../util/api/pc'
import { LinkCell, CopyCell, DescriptionCell } from './TrainingResources'
import styles from './resourceTable.module.styl'
import { Table, TableHeading, TableCell } from '@nike/eds'

export interface TemplateWithName extends Template {
  techSolutionName: string
}

interface TemplatesProps {
  templates: TemplateWithName[]
  isPlatformPage?: boolean
}

export const Templates = ({ templates = [], isPlatformPage }: TemplatesProps): JSX.Element => {
  const config = getConfig()
  const headers = ['Name', 'Description', 'Copy Link']

  if (isPlatformPage) headers.unshift('Tech Solution')
  return (
    <Table className={styles.resourcesTable}>
      <thead>
        <tr>
          {headers.map((header) => (
            <TableHeading key={header}>{header}</TableHeading>
          ))}
        </tr>
      </thead>
      <tbody>
        {templates.map((template: TemplateWithName) => (
          <tr key={template.name}>
            {isPlatformPage && <TableCell>{template.techSolutionName}</TableCell>}
            <LinkCell
              href={`${config.devportalUrl}/docs/projects/${template.name}`}
              displayName={template.name}
            />
            <DescriptionCell description={template.description} />
            <CopyCell href={`${config.devportalUrl}/docs/projects/${template.name}`} />
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
