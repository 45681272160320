import { PopoverWrapper } from '../PopoverWrapper'
import { UserAccessContext } from '../UserAccessContext'
import { IDLockerAccessStatus } from './IDLockerAccessStatus'
import { Roles } from './Roles'
import { useContext } from 'react'

export interface IDLockerProps {
  accessToken: string
  techSolutionId: string
}

export const IDLocker = ({ accessToken, techSolutionId }: IDLockerProps): JSX.Element | null => {
  const { idLocker } = useContext(UserAccessContext)

  if (!idLocker) {
    return null
  }

  if (idLocker.roles.length === 1) {
    return (
      <IDLockerAccessStatus
        role={idLocker.roles[0].role}
        techSolutionId={techSolutionId}
        accessToken={accessToken}
      />
    )
  }

  return (
    <PopoverWrapper>
      <Roles roles={idLocker.roles} techSolutionId={techSolutionId} accessToken={accessToken} />
    </PopoverWrapper>
  )
}
