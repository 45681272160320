import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

type ApiHeaders = Record<string, string>

interface ApiClient {
  post<P, R>(url: string, payload: P, headers: ApiHeaders, config?: RequestInit): Promise<R>
  get<R>(url: string, options?: RequestInit): Promise<R>
}

const CONTENT_TYPE = {
  json: 'application/json',
  octetStream: 'application/octet-stream',
  javascript: 'application/javascript',
  css: 'text/css',
} as const

const globalHeaders: { Authorization?: string } = {}

export function setAccessToken(accessToken: string) {
  globalHeaders.Authorization = `Bearer ${accessToken}`
}

export const apolloClient = (accessToken: string, platformConsoleApiUrl: string) => {
  const httpLink = createHttpLink({
    uri: platformConsoleApiUrl,
  })

  const authLink = setContext((_, { headers }) => ({
    // eslint-disable-next-line
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  }))

  const apolloClientConfig = {
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    query: { fetchPolicy: 'cache-and-network' },
  }

  return new ApolloClient(apolloClientConfig)
}

const processError = async (response: Response, contentType?: string) => {
  if (contentType?.includes(CONTENT_TYPE.json)) {
    return response.json()
  }
  throw new Error(response.status.toString())
}

const processResponse = (response: Response) => {
  const contentType = response?.headers?.get('content-type')?.replace(/ /g, '').toLowerCase()

  if (!response.ok && response.type !== 'opaque') {
    return processError(response, contentType)
  }

  if (!(response.status >= 200 && response.status < 400)) {
    return processError(response, contentType)
  }

  if (contentType?.includes(CONTENT_TYPE.json)) {
    return response.json()
  } else if (contentType === CONTENT_TYPE.octetStream) {
    return response.blob()
  } else if (contentType === CONTENT_TYPE.javascript) {
    return response.text()
  } else if (contentType?.includes(CONTENT_TYPE.css)) {
    return response.text()
  }
  return response
}

export const client: ApiClient = {
  async post<P, R>(url: string, payload: P, headers: ApiHeaders, config?: RequestInit): Promise<R> {
    const response = await fetch(url, {
      method: 'POST',
      headers: Object.assign({ 'Content-Type': CONTENT_TYPE.json, ...globalHeaders }, headers),
      body: JSON.stringify(payload),
      mode: 'cors',
      ...config,
    })
    return processResponse(response)
  },
  async get<R>(url: string, options: RequestInit = {}): Promise<R> {
    options = Object.assign({ headers: { ...globalHeaders } }, { ...options })
    const response = await fetch(url, options)
    return processResponse(response)
  },
}
