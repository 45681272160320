import { SnackbarContext } from '../Snack/Snack'
import { getConfig } from '../config'
import { getUser, updateUserFavorites, FavoriteInput } from '../util/api/pc'
import { FAVORITES_ACTIONS, LABELS, TYPES } from './consts'
import styles from './favoriteStatus.styl'
import { Tooltip, Icon, Spinner } from '@nike/eds'
import classnames from 'classnames'
import { useState, useEffect, useContext } from 'react'

interface FavoriteStatusProps {
  name: string
  techSolutionId: string
  platformConsoleApiUrl: string
  accessToken: string
}

export const FavoriteStatus = ({
  techSolutionId,
  platformConsoleApiUrl,
  accessToken,
  name,
}: FavoriteStatusProps): JSX.Element => {
  const { platformConsoleTechSolutionId } = getConfig()
  const [favorite, setFavorite] = useState<FavoriteInput | undefined>()
  const [disabled, setDisabled] = useState(false)
  const { createSnack } = useContext(SnackbarContext)

  const label = favorite ? LABELS.removeFavorite : LABELS.addFavorite
  const action = favorite ? FAVORITES_ACTIONS.REMOVE : FAVORITES_ACTIONS.ADD
  const favoriteInput = favorite ? favorite : { type: TYPES.techSolution, id: techSolutionId }

  const updateFavorite = async () => {
    if (disabled) return
    try {
      setDisabled(true)
      const data = await updateUserFavorites({
        platformConsoleApiUrl,
        accessToken,
        input: {
          action,
          input: favoriteInput,
        },
      })

      const message = data?.updateUserFavorites?.message
      const success = data?.updateUserFavorites?.success

      if (data) {
        if (success) {
          createSnack({
            message: `${name} ${message}`,
            status: action === FAVORITES_ACTIONS.ADD ? 'success' : 'info',
            id: 'data-success-info',
          })
        } else {
          const errorMessage = message.includes('Favorites reached the limit')
            ? message
            : `${name} ${message}`
          createSnack({
            message: errorMessage,
            status: 'error',
            id: 'data-error',
          })
        }
        await checkIfCurrentAFavorite()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setDisabled(false)
    }
  }

  const checkIfCurrentAFavorite = async () => {
    try {
      const { user } = await getUser({
        platformConsoleApiUrl,
        accessToken,
        techSolutionId: platformConsoleTechSolutionId,
      })
      const isCurrentAFavorite = user?.favorites?.find((el) => el?.id === techSolutionId)

      if (isCurrentAFavorite) {
        setFavorite({ id: isCurrentAFavorite.id, type: isCurrentAFavorite.type })
      } else {
        setFavorite(undefined)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    void checkIfCurrentAFavorite()
  }, [techSolutionId])

  return (
    <div className={classnames(styles.favoriteButton, { [styles.disabled]: disabled })}>
      <Tooltip
        isDark
        label={label}
        role='tooltip'
        aria-hidden='true'
        bodySlot={<div className={styles.tooltip}>{label}</div>}
      >
        {/** hack: EDS IconButton isn't hiding the tooltip after click */}
        <div
          className={classnames(
            'eds-button',
            'eds-button--secondary',
            'eds-button--small',
            'eds-button--icon',
            { 'eds-button--disabled': disabled }
          )}
          onClick={updateFavorite}
        >
          {disabled && <Spinner />}
          {!disabled && (
            <Icon
              name={favorite ? 'FavoriteFilled' : 'Favorite'}
              color={favorite ? 'var(--eds-color-red-40)' : ''}
            />
          )}
        </div>
      </Tooltip>
    </div>
  )
}
