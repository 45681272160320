import { Fields } from '../../AccessRequest/custom-form/fields/types'
import { FavoriteInput, RecentInput, ReleaseNotesLastSeenInput } from '../api/pc'
import { Query } from './queries'

interface UpdateUserFavoritesMutationParams {
  input: FavoriteInput
  action: string
}

interface RequestAccessMutationParams {
  techSolutionId: string
  role?: string
  customForm?: Fields
}

export const updateUserRecentsMutation = (input: RecentInput): Query<RecentInput> => ({
  operationName: 'updateUserRecents',
  variables: {
    input,
  },
  query: `
    mutation updateUserRecents($input: RecentInput!) {
      updateUserRecents(input: $input) {
        id
      }
    }
`,
})

export const updateUserFavoritesMutation = ({
  input,
  action,
}: UpdateUserFavoritesMutationParams): Query<FavoriteInput> => ({
  operationName: 'updateUserFavorites',
  variables: {
    input,
    action,
  },
  query: `
    mutation updateUserFavorites($action: ActionType!, $input: FavoriteInput!) {
      updateUserFavorites(action: $action, input: $input) {
        message 
        success  
      }
    }
`,
})

export const updateUserReleaseNotesLastSeenMutation = (
  input: ReleaseNotesLastSeenInput
): Query<ReleaseNotesLastSeenInput> => ({
  operationName: 'updateUserReleaseNotesLastSeen',
  variables: {
    techSolutionId: input.techSolutionId,
  },
  query: `
    mutation updateUserReleaseNotesLastSeen($techSolutionId: String!) {
      updateUserReleaseNotesLastSeen(techSolutionId: $techSolutionId) {
        releaseNotesLastSeen
      }
    }
`,
})

export const requestAccessMutation = ({
  techSolutionId,
  role,
  customForm,
}: RequestAccessMutationParams): Query => ({
  operationName: 'putUserAccessRequestForTechSolution',
  variables: {
    techSolutionId,
    role,
    customForm: JSON.stringify(customForm),
  },
  query: `
    mutation putUserAccessRequestForTechSolution($techSolutionId: ID!, $role: String, $customForm: AWSJSON) {
      putUserAccessRequestForTechSolution(techSolution: $techSolutionId, role: $role, customForm: $customForm) {
        status
        jira
        idLocker
        snow
      }
    }
  `,
})

export const prepareAdGroupsMutation = (techSolutionId: string): Query => ({
  operationName: 'prepareADGroupDetailsForAccessRequest',
  variables: {
    techSolutionId,
  },
  query: `
    mutation prepareADGroupDetailsForAccessRequest($techSolutionId: ID!) {
      prepareADGroupDetailsForAccessRequest(techSolutionId: $techSolutionId) {
        role
      }
    }
  `,
})

export const createSubscriptionMutation = (techSolutionId: string): Query => ({
  operationName: 'createSubscription',
  variables: {
    techSolutionId,
  },
  query: `
      mutation createSubscription($techSolutionId: ID!) {
          createSubscription(techSolutionId: $techSolutionId) {
            email
            techSolutionId
          }
        }
    `,
})

export const deleteSubscriptionMutation = (techSolutionId: string): Query => ({
  operationName: 'deleteSubscription',
  variables: {
    techSolutionId,
  },
  query: `
        mutation deleteSubscription($techSolutionId: ID!) {
            deleteSubscription(techSolutionId: $techSolutionId) 
          }
      `,
})
