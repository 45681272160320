import { RenderFieldProps } from './types'
import { Toggle as EdsToggle } from '@nike/eds'
import { Controller } from 'react-hook-form'

export const Toggle = ({ config, control }: RenderFieldProps): JSX.Element => (
  <Controller
    name={config.title}
    control={control}
    defaultValue={config.defaultValue || ''}
    render={({ field: { onChange, value } }) => (
      <EdsToggle
        id={config.title}
        label={config.title}
        checked={value ? true : false}
        onChange={onChange}
      />
    )}
  />
)
