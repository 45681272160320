import { RegionalHealth, Status } from '../util/api/pc'
import { STATUS_MAP } from './consts'

export const getStatus = (regionalHealth: RegionalHealth[]): Status => {
  if (regionalHealth?.some(({ status }) => status === STATUS_MAP.maintenance)) {
    return STATUS_MAP.maintenance
  }

  if (regionalHealth?.some(({ status }) => status === STATUS_MAP.fail)) {
    return STATUS_MAP.fail
  }

  if (regionalHealth?.some(({ status }) => status === STATUS_MAP.warn)) {
    return STATUS_MAP.warn
  }

  if (regionalHealth?.some(({ status }) => status === STATUS_MAP.pass)) {
    return STATUS_MAP.pass
  }

  return STATUS_MAP.undetermined
}

export const getHealthTitle = (
  regionalHealth: RegionalHealth[],
  techSolutionName: string
): string => {
  const healthStatus = getStatus(regionalHealth)
  const failStatuses = regionalHealth?.filter(({ status }) => status === STATUS_MAP.fail)
  const warnStatuses = regionalHealth?.filter(({ status }) => status === STATUS_MAP.warn)

  if (warnStatuses?.length) {
    return failStatuses?.length
      ? `(${failStatuses?.length}) Status Error, (${warnStatuses.length}) Warning`
      : `(${warnStatuses.length}) Status Warning`
  }

  if (failStatuses?.length) {
    return `(${failStatuses.length}) Status Error`
  }

  if (healthStatus === STATUS_MAP.maintenance) {
    return `${techSolutionName} has maintenance mode`
  }

  if (healthStatus === STATUS_MAP.undetermined) {
    return 'No Status available'
  }

  if (healthStatus === STATUS_MAP.pass) {
    return `${techSolutionName} is fully operational`
  }
  return ''
}
