import { Announcement } from '../util/api/pc'

/*
  Cache object structure:
  {
    {techSolutionId}: [announcementId]
  }
*/
const cacheName = 'CONSOLE_ANNOUNCEMENTS_DISMISSED'

export type Announcements = Announcement[]

export function dismissAnnouncement(announcementId: string, techSolutionId: string): void {
  let existing = parseStorageItem(cacheName)

  if (!existing) {
    existing = {}
  }

  if (!existing[techSolutionId] || !Array.isArray(existing[techSolutionId])) {
    existing[techSolutionId] = []
  }
  existing[techSolutionId].push(announcementId)

  const newAnnouncementCache = JSON.stringify(existing)
  localStorage.setItem(cacheName, newAnnouncementCache)
}

function removeExpiredDismissal(dismissed: string[], techSolutionId: string) {
  const existing = parseStorageItem(cacheName)
  if (existing) {
    existing[techSolutionId] = dismissed
    const newAnnouncementCache = JSON.stringify(existing)
    localStorage.setItem(cacheName, newAnnouncementCache)
  }
}

export function checkAnnouncements(
  announcements: Announcements,
  techSolutionId: string
): Announcements {
  const dismissed = getDismissedAnnouncementCache(techSolutionId)

  let filteredAnnouncements: Announcements =
    announcements?.filter((announcement: Announcement) => {
      if (!announcement?.releasedOn) {
        return true
      }

      return new Date(announcement.releasedOn) < new Date()
    }) || []

  if (dismissed?.length) {
    const updatedDismissed = dismissed.filter((id) =>
      filteredAnnouncements.find((a) => a.id === id)
    )
    if (updatedDismissed.length !== dismissed.length) {
      removeExpiredDismissal(updatedDismissed, techSolutionId)
    }

    filteredAnnouncements = filteredAnnouncements.filter((a) => !dismissed.includes(a.id))
  }

  return filteredAnnouncements
}

function getDismissedAnnouncementCache(techSolutionId: string): string[] | undefined {
  const existing = parseStorageItem(cacheName)
  return existing?.[techSolutionId]
}

export function createAnnouncementId({ markdown }: { markdown: string }): string {
  return `${markdown.replace(/\n/g, '')}`
}

function parseStorageItem(type: string) {
  const existing = localStorage.getItem(type)
  return existing ? tryParse(existing) : null
}

function tryParse(obj: string): Record<string, string[]> {
  try {
    return JSON.parse(obj) as Record<string, string[]>
  } catch (error) {
    throw new Error('Unable to parse storage item')
  }
}
