import styles from './tabs.styl'
import { TabGroup, Tab } from '@nike/eds'
import qs from 'qs'
import { navigate } from 'raviger'
import { useState, ChangeEventHandler, FC, useEffect } from 'react'

export interface TabData {
  label: string
  labelAside?: JSX.Element
  content: JSX.Element
}

export interface TabsProps {
  name: string
  tabs: TabData[]
  onChange?: (tab: TabData | null) => void
}

const prepareLabel = (label: string) => label.replaceAll(' ', '-').toLowerCase()

const getActiveTab = (tabs: TabData[]): number => {
  const search = qs.parse(window.location.search)

  if (search['?activeTab']) {
    const activeIndex = tabs.findIndex(({ label }) => prepareLabel(label) === search['?activeTab'])
    return activeIndex >= 0 ? activeIndex : 0
  }
  return 0
}

export const Tabs: FC<TabsProps> = ({ name, tabs, onChange }) => {
  const [activeTabId, setActiveTabId] = useState(getActiveTab(tabs))
  const content = tabs[activeTabId]?.content

  useEffect(() => {
    onChange?.(typeof activeTabId === 'number' ? tabs[activeTabId] : null)
  }, [activeTabId])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { id } = event.target
    const tabId = parseInt(id)
    setActiveTabId(tabId)

    // Uses it's own navigate because we want history.pushState even in integrated apps
    navigate(`${window.location.pathname}?activeTab=${prepareLabel(tabs[tabId].label)}`)
  }

  return (
    <>
      <TabGroup
        role='tablist'
        name={name}
        activeId={activeTabId.toString()}
        onChange={handleChange}
      >
        {tabs.map((tab, i) => (
          <Tab key={tab.label} id={i.toString()} name={tab.label} role='tab'>
            {tab.label}
            {tab.labelAside}
          </Tab>
        ))}
      </TabGroup>
      <div role='tabpanel' className={styles.tabGroupContent}>
        {content}
      </div>
    </>
  )
}
