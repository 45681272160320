import { EssentialCard } from '../../EssentialCard'
import { TabData, TabsProps, Tabs } from '../../components/Tabs'
import type { TechSolution, TechSolutionResponse, Template } from '../../util/api/pc'
import { CardRows } from '../CardRows'
import styles from '../homePage.styl'
import { Contacts } from './Contacts'
import { ReleaseNotes } from './ReleaseNotes'
import { Roadmap } from './Roadmap'
import { Templates, TemplateWithName } from './Templates'
import { TrainingResourceWithName, TrainingResources } from './TrainingResources'
import { Videos } from './Videos'
import { WidgetCardResolver } from './WidgetCardResolver'
import { sortWidgets } from './util'
import { Widget, WidgetLifecycleState } from '@nike/cx-widget'
import { useMemo, useState } from 'react'

interface TabsGroupProps
  extends Pick<TechSolutionResponse, 'relatedTechSolutions'>,
    Pick<
      TechSolution,
      | 'contacts'
      | 'owningTeamName'
      | 'roadmapLink'
      | 'owningTeam'
      | 'trainingResources'
      | 'releaseNotes'
      | 'metricWidgets'
      | 'videos'
    > {
  navigate?: (href: string) => void
  techSolutionName: string
  devportalUrl: string
  templates: Template[]
  hasTemplates: boolean
  newReleaseNotesCount?: number
  releaseNotesLastSeen?: string
  updateUserReleaseNotesLastSeen: () => Promise<unknown>
  accessToken: string
}

export const TabsGroup = ({
  relatedTechSolutions,
  roadmapLink,
  navigate,
  techSolutionName,
  contacts,
  owningTeamName,
  owningTeam,
  devportalUrl,
  templates,
  hasTemplates,
  trainingResources,
  releaseNotes,
  newReleaseNotesCount: initialNewReleaseNotesCount,
  releaseNotesLastSeen,
  updateUserReleaseNotesLastSeen,
  metricWidgets,
  accessToken,
  videos,
}: TabsGroupProps): JSX.Element => {
  const [newReleaseNotesCount, setNewReleaseNotesCount] = useState<number>(
    initialNewReleaseNotesCount ?? 0
  )

  const tabs = useMemo(() => {
    const tempTabs: TabData[] = []
    if (contacts?.length || owningTeamName) {
      tempTabs.push({
        label: 'Contact',
        content: (
          <Contacts
            contacts={contacts || []}
            owningTeamName={owningTeamName}
            owningTeam={owningTeam}
            devportalUrl={devportalUrl}
          />
        ),
      })
    }
    if (trainingResources?.length) {
      tempTabs.push({
        label: 'Training Resources',
        content: (
          <TrainingResources trainingResources={trainingResources as TrainingResourceWithName[]} />
        ),
      })
    }
    if (hasTemplates) {
      tempTabs.push({
        label: 'Templates',
        content: <Templates templates={templates as TemplateWithName[]} />,
      })
    }
    if (releaseNotes?.length) {
      tempTabs.push({
        label: 'Release Notes',
        labelAside: newReleaseNotesCount ? (
          <div className={styles.notificationBadge}>{newReleaseNotesCount}</div>
        ) : undefined,
        content: (
          <ReleaseNotes
            releaseNotes={releaseNotes}
            releaseNotesLastSeen={releaseNotesLastSeen ?? ''}
            techSolutionName={techSolutionName}
          />
        ),
      })
    }
    if (roadmapLink?.includes('http')) {
      tempTabs.push({
        label: 'Roadmap',
        content: <Roadmap roadmapLink={roadmapLink} />,
      })
    }
    if (relatedTechSolutions?.length) {
      tempTabs.push({
        label: 'Related Tech Solutions',
        content: (
          <CardRows>
            {relatedTechSolutions.map((tech) => (
              <EssentialCard key={tech.id} navigate={navigate} {...tech} />
            ))}
          </CardRows>
        ),
      })
    }

    const filteredWidgets = metricWidgets?.filter(
      ({ lifecycleState }) =>
        lifecycleState === WidgetLifecycleState.RELEASE ||
        lifecycleState === WidgetLifecycleState.DEPRECATED
    )

    if (filteredWidgets?.length) {
      tempTabs.push({
        label: 'Widgets',
        content: (
          <div className={styles.widgetContainer}>
            {filteredWidgets.sort(sortWidgets).map((widget: Widget) => (
              <WidgetCardResolver
                key={widget.id || widget.name}
                widget={widget}
                accessToken={accessToken}
              />
            ))}
          </div>
        ),
      })
    }

    if (videos?.length) {
      tempTabs.push({
        label: 'Videos',
        content: <Videos videos={videos} />,
      })
    }

    return tempTabs
  }, [
    relatedTechSolutions,
    roadmapLink,
    contacts,
    releaseNotes,
    newReleaseNotesCount,
    releaseNotesLastSeen,
  ])

  const onTabChange: TabsProps['onChange'] = (tab) => {
    if (tab?.label === 'Release Notes') {
      setNewReleaseNotesCount(0)
      void updateUserReleaseNotesLastSeen()
    }
  }

  return <Tabs name='platform-tabs' tabs={tabs} onChange={onTabChange} />
}
