import { SnackbarContext } from '../../Snack/Snack'
import { useReactSearch } from '../util'
import { ReleaseNoteBody } from './ReleaseNoteBody'
import { ReleaseNoteHeader } from './ReleaseNoteHeader'
import { ReleaseNoteModal } from './ReleaseNoteModal'
import styles from './releaseNotes.module.styl'
import { releaseNoteUrl } from './util'
import { Divider } from '@nike/eds'
import qs from 'qs'
import { useContext, useEffect, useState } from 'react'
import type { ReleaseNote } from 'util/api/pc'

export interface ReleaseNotesProps {
  releaseNotes: ReleaseNote[]
  releaseNotesLastSeen: string
  techSolutionName: string
}

/**
 * Content for the Release Notes tab that lists the release notes for a tech solution.
 */
export const ReleaseNotes = ({
  releaseNotes,
  releaseNotesLastSeen,
  techSolutionName,
}: ReleaseNotesProps): JSX.Element => {
  const [focusedReleaseNote, setFocusedReleaseNote] = useState<ReleaseNote | undefined>(undefined)
  const { createSnack } = useContext(SnackbarContext)

  const search = useReactSearch()
  // Show modal when user navigates to page with ?activeTab=release-notes&noteId=... in URL
  useEffect(() => {
    const { activeTab, noteId } = qs.parse(search, { ignoreQueryPrefix: true })
    if (activeTab === 'release-notes') {
      setFocusedReleaseNote(releaseNotes.find((releaseNote) => noteId === releaseNote.id))
    }
  }, [search])

  return (
    <div className={styles.releaseNotes}>
      <div>
        {releaseNotes.map((releaseNote, idx) => (
          <div key={releaseNote.id} data-testid={`release-note-${idx}`}>
            <ReleaseNoteHeader
              releaseNote={releaseNote}
              releaseNoteUrl={releaseNoteUrl}
              releaseNotesLastSeen={releaseNotesLastSeen}
              createSnack={createSnack}
              setFocusedReleaseNote={setFocusedReleaseNote}
            />
            <ReleaseNoteBody releaseNote={releaseNote} />
            {idx === releaseNotes.length - 1 ? null : <Divider className={styles.divider} />}
          </div>
        ))}
      </div>
      <ReleaseNoteModal
        onDismiss={() => {
          window.history.pushState(null, document.title, releaseNoteUrl())
          setFocusedReleaseNote(undefined)
        }}
        releaseNote={focusedReleaseNote}
        releaseNoteUrl={releaseNoteUrl}
        createSnack={createSnack}
        techSolutionName={techSolutionName}
      />
    </div>
  )
}
