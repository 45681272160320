import { SnackbarContext } from '../Snack/Snack'
import { getSubscription, createSubscription, deleteSubscription } from '../util/api/pc'
import styles from './subscription.styl'
import { Tooltip, Button, Icon, Spinner } from '@nike/eds'
import classnames from 'classnames'
import { useContext, useEffect, useState } from 'react'

export interface SubscribeButtonProps {
  techSolutionId: string
  platformConsoleApiUrl: string
  accessToken: string
  name: string
}
export const SubscribeButton = ({
  techSolutionId,
  platformConsoleApiUrl,
  accessToken,
  name,
}: SubscribeButtonProps): JSX.Element => {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(false)
  const { createSnack } = useContext(SnackbarContext)

  const checkIfSubscribed = async () => {
    try {
      const subscription = await getSubscription({
        techSolutionId,
        platformConsoleApiUrl,
        accessToken,
      })

      if (subscription?.subscriptionForUser) {
        setIsSubscribed(true)
      } else {
        setIsSubscribed(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubscription = async () => {
    setDisabled(true)
    if (isSubscribed) {
      try {
        const data = await deleteSubscription({
          techSolutionId,
          platformConsoleApiUrl,
          accessToken,
        })
        if (data.deleteSubscription) {
          createSnack({
            message: `You have unsubscribed from ${name} notifications`,
            status: 'info',
            id: 'subscription-info',
          })
          setIsSubscribed(!isSubscribed)
        }
      } catch (error) {
        createSnack({
          message: `Error removing subscription: ${String((error as Error).message)}`,
          status: 'error',
          id: 'subscription-error',
        })
      }
    } else {
      try {
        const data = await createSubscription({
          techSolutionId,
          platformConsoleApiUrl,
          accessToken,
        })

        if (data.createSubscription) {
          createSnack({
            message: `You have subscribed to ${name} notifications`,
            status: 'success',
            id: 'subscription-success',
          })
          setIsSubscribed(!isSubscribed)
        }
      } catch (error) {
        createSnack({
          message: `Error creating subscription: ${String((error as Error).message)}`,
          status: 'error',
          id: 'create-subscription-error',
        })
      }
    }
    setDisabled(false)
  }

  useEffect(() => {
    void checkIfSubscribed()
  }, [techSolutionId])

  return (
    <div className={classnames(styles.subscriptionButton, { [styles.disabled]: disabled })}>
      <Tooltip
        isDark
        role='tooltip'
        aria-hidden='true'
        bodySlot={
          <div className={styles.tooltip}>
            {isSubscribed ? 'Unsubscribe from notifications' : 'Subscribe to notifications'}
          </div>
        }
      >
        <Button
          disabled={disabled}
          size='small'
          variant='secondary'
          onClick={handleSubscription}
          beforeSlot={
            disabled ? (
              <Spinner />
            ) : isSubscribed ? (
              <Icon name='Check' size='s' />
            ) : (
              <Icon name='Notification' size='s' />
            )
          }
        >
          {isSubscribed ? 'Subscribed' : 'Subscribe'}
        </Button>
      </Tooltip>
    </div>
  )
}
