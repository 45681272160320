// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kfLDtX8imlQcOuHIExpu {
  margin: 40px 0px 32px;
}
.IvoB_ooD0_nuRdmzB8Al {
  margin-left: 12px;
}
.eds-popover:after {
  display: none;
}
.eds-popover[data-popper-placement=top] {
  margin-bottom: 0;
}
.eds-popover[data-popper-placement=bottom] {
  margin-top: 0;
}
.b7qqAf6x5ldENgOrpD9g {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
.b7qqAf6x5ldENgOrpD9g a {
  color: var(--eds-color-white);
  text-decoration-color: var(--eds-color-white);
  font-size: 14px;
}
.b7qqAf6x5ldENgOrpD9g a:hover {
  text-decoration: underline;
}
.ZVsUpU1BZrc6ucv52Wnr {
  margin-right: 8px;
}
.ZVsUpU1BZrc6ucv52Wnr > div {
  width: 16px;
  height: 16px;
}
.XbYPQh3weFQzCNH717Qy,
.MhvFNKiyQyOLT6ewW_Ux {
  padding-left: 38px;
}
.XbYPQh3weFQzCNH717Qy,
.MhvFNKiyQyOLT6ewW_Ux,
.k0R_BZBfT0UITUCyoG9H {
  color: var(--eds-color-grey-7);
}
.MhvFNKiyQyOLT6ewW_Ux {
  display: flex;
  gap: var(--eds-space-16);
}
.qqJAxluCcOi5v9AtaKUd {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/HealthStatus/healthStatus.styl"],"names":[],"mappings":"AAEA;EACE,qBAAO;AADT;AAGA;EACE,iBAAY;AADd;AAKI;EACE,aAAQ;AAHd;AAII;EACE,gBAAc;AAFpB;AAGI;EACE,aAAW;AADjB;AAGA;EACE,aAAQ;EACR,mBAAY;EACZ,cAAS;AADX;AAGE;EACE,6BAAM;EACN,6CAAsB;EACtB,eAAW;AADf;AAEI;EACC,0BAAgB;AAArB;AAEA;EACE,iBAAa;AAAf;AACE;EACE,WAAM;EACN,YAAO;AACX;AAEA;;EAEE,kBAAa;AAAf;AAEA;;;EAGE,8BAAM;AAAR;AAEA;EACE,aAAQ;EACR,wBAAI;AAAN;AAEA;EACE,aAAQ;EACR,mBAAY;AAAd","sourcesContent":["@require '../styles/variables'\n\n.health\n  margin 40px 0px 32px\n\n.status\n  margin-left 12px\n\n:global\n  .eds-popover\n    &:after\n      display none\n    &[data-popper-placement=top]\n      margin-bottom 0\n    &[data-popper-placement=bottom]\n      margin-top 0\n\n.region\n  display flex\n  align-items center\n  padding: 4px 0\n\n  a\n    color var(--eds-color-white)\n    text-decoration-color var(--eds-color-white)\n    font-size: 14px\n    &:hover\n     text-decoration underline\n\n.statusIcon\n  margin-right 8px\n  > div\n    width 16px\n    height 16px\n\n\n.lastUpdated,\n.uptime\n  padding-left 38px // margin 12 + icon size (26px)\n\n.lastUpdated,\n.uptime,\n.uptimeDetail\n  color var(--eds-color-grey-7)\n\n.uptime\n  display flex\n  gap var(--eds-space-16)\n\n.iconRow\n  display flex\n  align-items center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"health": `kfLDtX8imlQcOuHIExpu`,
	"status": `IvoB_ooD0_nuRdmzB8Al`,
	"region": `b7qqAf6x5ldENgOrpD9g`,
	"statusIcon": `ZVsUpU1BZrc6ucv52Wnr`,
	"lastUpdated": `XbYPQh3weFQzCNH717Qy`,
	"uptime": `MhvFNKiyQyOLT6ewW_Ux`,
	"uptimeDetail": `k0R_BZBfT0UITUCyoG9H`,
	"iconRow": `qqJAxluCcOi5v9AtaKUd`
};
export default ___CSS_LOADER_EXPORT___;
