import { SnackbarContext } from '../../Snack/Snack'
import { Video } from '../../util/api/pc'
import { useReactSearch } from '../util'
import styles from './../homePage.styl'
import { VideoModal } from './VideoModal'
import { getVideoUrl } from './util'
import { Card, IconButton, Text } from '@nike/eds'
import qs from 'qs'
import { useState, useEffect, useContext } from 'react'

interface VideosProps {
  videos: Video[]
}

export const Videos = ({ videos }: VideosProps) => {
  const [videoToPreview, setVideoToPreview] = useState<Video | undefined>()
  const search = useReactSearch()
  const { createSnack } = useContext(SnackbarContext)

  const closeModal = () => {
    setVideoToPreview(undefined)
    window.history.pushState(null, document.title, getVideoUrl())
  }

  const copyVideoLink = async (id: string) => {
    await navigator.clipboard.writeText(getVideoUrl(id))
    createSnack({
      id: 'video-link-copied',
      status: 'success',
      message: 'Link copied to clipboard',
    })
  }

  const openVideoModal = (video: Video) => {
    window.history.pushState(null, document.title, getVideoUrl(video.id))
    setVideoToPreview(video)
  }

  useEffect(() => {
    const { activeTab, videoId } = qs.parse(search, { ignoreQueryPrefix: true })
    if (activeTab === 'videos') {
      setVideoToPreview(videos.find((video) => videoId === video.id))
    }
  }, [search])

  return (
    <div className={styles.videosContainer}>
      {videos.map((video) => (
        <Card key={video.id}>
          <div className={styles.videoHeader}>
            <Text
              className={styles.videoName}
              as='button'
              font='title-6'
              onClick={() => openVideoModal(video)}
            >
              {video.name}
            </Text>
            <IconButton
              label='Copy video URL'
              aria-label='Copy video URL'
              variant='ghost'
              size='small'
              icon='Link'
              color='secondary'
              onClick={() => copyVideoLink(video.id)}
            />
          </div>
          <video className={styles.video} src={video.url} controls data-testid='video-content' />
        </Card>
      ))}
      {videoToPreview && (
        <VideoModal video={videoToPreview} closeModal={closeModal} copyVideoLink={copyVideoLink} />
      )}
    </div>
  )
}
