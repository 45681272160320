export const FAVORITES_ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
} as const

export const LABELS = {
  addFavorite: 'Add Favorite',
  removeFavorite: 'Remove Favorite',
} as const

export const TYPES = {
  techSolution: 'TECH_SOLUTION',
} as const
