// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j07d0csr4Sj1AWVtrXSR {
  cursor: pointer;
}
.j07d0csr4Sj1AWVtrXSR.hFUw4hl6CTnFtzKNKdZJ {
  cursor: not-allowed;
}
.j07d0csr4Sj1AWVtrXSR .oVinTll6hLm36WNdxvrt {
  display: flex;
  justify-content: center;
  width: 225px;
  margin: 4px;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/SubscribeButton/subscription.styl"],"names":[],"mappings":"AAEA;EACE,eAAO;AADT;AAEE;EACE,mBAAO;AAAX;AAEE;EACE,aAAS;EACT,uBAAiB;EACjB,YAAM;EACN,WAAO;EACP,WAAM;AAAV","sourcesContent":["@require '../styles/variables'\n\n.subscriptionButton\n  cursor pointer\n  &.disabled\n    cursor not-allowed\n\n  .tooltip\n    display: flex\n    justify-content: center\n    width 225px\n    margin 4px\n    color eds-color-white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscriptionButton": `j07d0csr4Sj1AWVtrXSR`,
	"disabled": `hFUw4hl6CTnFtzKNKdZJ`,
	"tooltip": `oVinTll6hLm36WNdxvrt`
};
export default ___CSS_LOADER_EXPORT___;
