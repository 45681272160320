import { getConfig } from '../../config'
import { client } from './client'

export async function authDAMSession(): Promise<void> {
  const config = getConfig()
  try {
    await client.post(`${config.damApiUrl}/auth/session`, {}, {}, { credentials: 'include' })
  } catch (error) {
    console.error('Failed to create DAM session:', error)
  }
}
