// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UEXlRGYVCXquhiHlQYUj {
  padding: 12px 24px;
  margin-bottom: 5px;
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: var(--eds-color-blue-05);
}
.UEXlRGYVCXquhiHlQYUj.yg3I1CXIPUQ7AIatQOWI {
  background-color: var(--eds-color-red-05);
}
.UEXlRGYVCXquhiHlQYUj.oRR2uvJ8UkP8FUL6DpmD {
  background-color: var(--eds-color-green-05);
}
.UEXlRGYVCXquhiHlQYUj.W4_lIp8I36fyB8iXQC3O {
  background-color: #fef7e3;
}
.UEXlRGYVCXquhiHlQYUj .mTBMn0uLcfiiHHjhYrZQ > :first-child {
  padding: 0;
}
.UEXlRGYVCXquhiHlQYUj .OmAheob7vYh0Z8HZOv20 > :first-child {
  background-color: var(--eds-color-blue-50) !important;
}
`, "",{"version":3,"sources":["webpack://./src/Announcements/announcements.styl"],"names":[],"mappings":"AAEA;EACE,kBAAQ;EACR,kBAAc;EACd,aAAQ;EACR,SAAI;EACJ,mBAAY;EACZ,0CAAiB;AADnB;AAGE;EACE,yCAAiB;AADrB;AAEE;EACE,2CAAiB;AAArB;AACE;EACE,yBAAiB;AACrB;AACE;EACE,UAAQ;AACZ;AAEI;EACE,qDAAiB;AAAvB","sourcesContent":["@require '../styles/variables'\n\n.announcement\n  padding 12px 24px\n  margin-bottom 5px\n  display flex\n  gap 16px\n  align-items center\n  background-color var(--eds-color-blue-05)\n\n  &.error\n    background-color var(--eds-color-red-05)\n  &.success\n    background-color var(--eds-color-green-05)\n  &.warning\n    background-color warning-bg\n\n  .icon > :first-child\n    padding 0\n\n  .infoIcon\n    > :first-child\n      background-color var(--eds-color-blue-50) !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"announcement": `UEXlRGYVCXquhiHlQYUj`,
	"error": `yg3I1CXIPUQ7AIatQOWI`,
	"success": `oRR2uvJ8UkP8FUL6DpmD`,
	"warning": `W4_lIp8I36fyB8iXQC3O`,
	"icon": `mTBMn0uLcfiiHHjhYrZQ`,
	"infoIcon": `OmAheob7vYh0Z8HZOv20`
};
export default ___CSS_LOADER_EXPORT___;
