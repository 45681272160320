import { UserAccessContext } from './UserAccessContext'
import styles from './accessRequest.styl'
import { TEXT_LABELS } from './consts'
import { Button, Icon, IconButton, Popover } from '@nike/eds'
import { FC, useContext, useState } from 'react'

export const PopoverWrapper: FC = ({ children }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const { modalIsVisible } = useContext(UserAccessContext)

  return (
    <div
      className={isPopoverVisible ? styles.popoverOpen : styles.popoverClosed}
      data-testid='id-locker'
    >
      <Popover
        isDark
        isOpen
        onClickOutside={() => (modalIsVisible.current ? null : setIsPopoverVisible(false))}
        modifiers={[
          {
            name: 'applyStyles',
            enabled: true,
            phase: 'write',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            fn: () => {},
          },
        ]}
        bodySlot={
          <div className={styles.popoverBody}>
            {children}

            <IconButton
              icon='Close'
              size='small'
              onClick={() => setIsPopoverVisible(false)}
              label='Close'
            />
          </div>
        }
      >
        <Button
          onClick={() => setIsPopoverVisible(true)}
          afterSlot={<Icon name='CaretDown' />}
          size='small'
        >
          {TEXT_LABELS.showAccessOptions}
        </Button>
      </Popover>
    </div>
  )
}
