// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xBXvqv4Xt_v4Bz_Ygr9r div {
  padding: 3px;
}
.xBXvqv4Xt_v4Bz_Ygr9r div svg {
  width: 20px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/StatusIndicator/statusIndicator.styl"],"names":[],"mappings":"AAIE;EACE,YAAQ;AAHZ;AAII;EACE,WAAM;EACN,YAAO;AAFb","sourcesContent":["@require '../../styles/variables'\n\n.statusIcon\n  // eds \"small\" icon is too big\n  div\n    padding 3px\n    svg\n      width 20px\n      height 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusIcon": `xBXvqv4Xt_v4Bz_Ygr9r`
};
export default ___CSS_LOADER_EXPORT___;
