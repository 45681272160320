import { getConfig } from '../../config'
import { client } from '../../util/api/client'
import { DevPortalTemplate } from './pc'

/**
 * Gets a project from the DevPortal.
 * @param {string} name
 * @returns project | undefined
 */
export async function getTemplateProjectFromDevPortal(
  name: string
): Promise<DevPortalTemplate | undefined> {
  const config = getConfig()

  let project: DevPortalTemplate | undefined = undefined
  try {
    project = await client.get(`${config.devportalApiUrl}/projects/${name}`)
  } catch (error: unknown) {
    console.warn(`Failed to get template project: ${(error as Error)?.message}`)
  }
  return project
}
