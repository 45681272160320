import { Field, Option, RenderFieldProps } from './types'
import { multiEmailValidation } from './validation'
import { Select } from '@nike/eds'
import { Controller, ControllerRenderProps } from 'react-hook-form'

export const MultiEmail = ({ config, control }: RenderFieldProps): JSX.Element => (
  <Controller
    name={config.title}
    control={control}
    defaultValue={
      config.defaultValue ? [{ label: config.defaultValue, value: config.defaultValue }] : []
    }
    rules={{
      validate: multiEmailValidation(config),
    }}
    render={({ field, fieldState: { error } }) => (
      <Select
        id={config.title}
        label={config.title}
        placeholder={config?.placeholder}
        hasErrors={!!error}
        errorMessage={error?.message}
        options={config.options}
        required={config.required}
        subtitle={config?.subtitle}
        menuPosition='fixed'
        isCreatable
        isMulti
        {...(field as ControllerRenderProps<Field<Option<string>[]>>)}
      />
    )}
  />
)
