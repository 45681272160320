// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kRMNQv8e4_ydJ0JdW278 {
  text-align: center;
  padding-top: 20%;
}
.kRMNQv8e4_ydJ0JdW278 .q8dlMiOA05w1DlNNt0LT {
  font: var(--eds-type-title-3);
  margin-bottom: 20px;
}
.kRMNQv8e4_ydJ0JdW278 .C_1BTV83Wq7yvQ6lKwxV {
  font: var(--eds-type-body-1);
}
.kRMNQv8e4_ydJ0JdW278 .Kinw9JmJWVNSJZxPmAP7 {
  color: var(--eds-color-brand-orange);
  width: 100px;
  height: 100px;
}
.kRMNQv8e4_ydJ0JdW278 .Kinw9JmJWVNSJZxPmAP7 * {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorMessage/errorMessage.styl"],"names":[],"mappings":"AAEA;EACE,kBAAW;EACX,gBAAY;AADd;AAGE;EACE,6BAAK;EACL,mBAAc;AADlB;AAGE;EACE,4BAAK;AADT;AAIE;EACE,oCAAM;EACN,YAAM;EACN,aAAO;AAFX;AAGE;EACE,WAAQ;EACR,YAAQ;AADZ","sourcesContent":["@require '../../styles/variables'\n\n.errorWrapper\n  text-align center\n  padding-top 20%\n\n  .heading\n    font var(--eds-type-title-3)\n    margin-bottom 20px\n\n  .text\n    font var(--eds-type-body-1)\n\n  // Resize SVG\n  .swoosh\n    color var(--eds-color-brand-orange)\n    width 100px\n    height 100px\n  .swoosh *\n    width:  100%\n    height: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorWrapper": `kRMNQv8e4_ydJ0JdW278`,
	"heading": `q8dlMiOA05w1DlNNt0LT`,
	"text": `C_1BTV83Wq7yvQ6lKwxV`,
	"swoosh": `Kinw9JmJWVNSJZxPmAP7`
};
export default ___CSS_LOADER_EXPORT___;
