import { getConfig } from '../../config'
import {
  getUserAccessStatus,
  prepareAdGroupsForRequest,
  putUserAccessRequest,
  RequestStatus,
} from '../../util/api/pc'
import { Status } from '../Status'
import { UserAccessContext } from '../UserAccessContext'
import styles from '../accessRequest.styl'
import { REQUEST_STATUS, USER_ACCESS_TYPE } from '../consts'
import { Fields } from '../custom-form/fields/types'
import { JiraCustomForm } from '../jira/JiraCustomForm'
import { useUserAccessNarkLog } from '../utils'
import { Spinner } from '@nike/eds'
import { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export interface IDLockerAccessStatusProps {
  role: string
  techSolutionId: string
  accessToken: string
}

export const IDLockerAccessStatus = ({
  role,
  techSolutionId,
  accessToken,
}: IDLockerAccessStatusProps): JSX.Element | null => {
  const {
    portalRef,
    modalIsVisible,
    accessMethodsCount,
    statuses,
    jira: jiraSetting,
    setStatuses,
  } = useContext(UserAccessContext)
  const { nark } = useUserAccessNarkLog(techSolutionId, USER_ACCESS_TYPE.idlocker)
  const [isLoading, setIsLoading] = useState(true)
  const [status, setStatus] = useState<RequestStatus>()
  const [isAccessRequest, setIsAccessRequest] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const { platformConsoleApiUrl } = getConfig()
  const isMultiAccess = accessMethodsCount > 1

  const requestAccess = async (customForm?: Fields) => {
    setShowModal(false)
    modalIsVisible.current = false
    setIsLoading(true)
    setIsAccessRequest(true)

    try {
      const { errors } = await prepareAdGroupsForRequest({
        platformConsoleApiUrl,
        techSolutionId,
        accessToken,
      })

      if (errors) {
        setStatus(REQUEST_STATUS.failed)
        nark(errors)
        return
      }
    } catch (error) {
      console.error('Error preparing adGroups', error)
      nark(error)
    }

    try {
      const { userAccessStatus, jira, snow, idLocker, errors } = await putUserAccessRequest({
        platformConsoleApiUrl,
        techSolutionId,
        accessToken,
        role,
        customForm,
      })
      if (errors) {
        setStatus(REQUEST_STATUS.failed)
        nark(errors)
      } else {
        setStatus(userAccessStatus)
        setStatuses((currentStatuses) => ({
          jira,
          snow,
          idLocker: { ...currentStatuses.idLocker, [role]: idLocker },
        }))
      }
    } catch (error) {
      console.error('Error requesting access', error)
      nark(error)
    } finally {
      setIsLoading(false)
    }
  }

  const toggleJiraJiraCustomForm = () => {
    setShowModal((val) => !val)
    modalIsVisible.current = !modalIsVisible.current
  }

  const handleRequest = () => {
    if (
      jiraSetting?.customForm &&
      ['available', 'failed', 'declined'].includes(statuses.jira || '')
    ) {
      toggleJiraJiraCustomForm()
      return
    }

    void requestAccess()
  }

  const fetchAccessStatus = async () => {
    try {
      setIsLoading(true)
      const { userAccessStatus, jira, snow, idLocker, errors } = await getUserAccessStatus({
        platformConsoleApiUrl,
        techSolutionId,
        accessToken,
        role,
      })
      if (errors) {
        setStatus(REQUEST_STATUS.failed)
        nark(errors, 'getUserAccessStatus')
      } else {
        setStatus(userAccessStatus)
        setStatuses((currentStatuses) => ({
          jira,
          snow,
          idLocker: { ...currentStatuses.idLocker, [role]: idLocker },
        }))
      }
    } catch (error) {
      console.error('Error fetching status', error)
      nark(error, 'getUserAccessStatus')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void fetchAccessStatus()
  }, [techSolutionId])

  if (isLoading) {
    return (
      <div className={styles.accessWrapper} data-testid='loader'>
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <Status
        status={status}
        requestAccess={handleRequest}
        isAccessRequest={isAccessRequest}
        fetchStatus={fetchAccessStatus}
        role={role}
        displayInfo={!isMultiAccess}
      />
      {!!portalRef.current &&
        showModal &&
        createPortal(
          <JiraCustomForm
            accessToken={accessToken}
            onSubmit={requestAccess}
            onDismiss={toggleJiraJiraCustomForm}
          />,
          portalRef.current
        )}
    </>
  )
}
