import { RenderFieldProps } from './types'
import { CheckboxGroup, Checkbox as EdsCheckbox } from '@nike/eds'
import { useState } from 'react'
import { useController } from 'react-hook-form'

export const Checkbox = ({ config, control }: RenderFieldProps): JSX.Element => {
  const [value, setValue] = useState(config.defaultValue ? [config.defaultValue] : [])
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: config.title,
  })

  return (
    <CheckboxGroup
      id={config.title}
      label={config.title}
      defaultValue={config.defaultValue || []}
      hasErrors={!!error}
      errorMessage={error?.message}
      subtitle={config?.subtitle}
      onChange={(event) => {
        const valueCopy = [...value]
        const option = event.target.value
        const index = valueCopy.findIndex((element) => element === option)

        if (index === -1) {
          valueCopy.push(option)
        } else {
          valueCopy.splice(index, 1)
        }

        field.onChange(valueCopy)
        setValue(valueCopy)
      }}
    >
      {config.options.map((option) => (
        <EdsCheckbox
          label={option.label}
          value={option.value}
          id={`${config.title} - ${option.value}`}
          key={`${config.title} - ${option.value}`}
        />
      ))}
    </CheckboxGroup>
  )
}
