import { createAnnouncementId } from '../Announcements'
import { Announcement } from '../util/api/pc'
import { Team } from '../util/api/teams'
import { useEffect, useState } from 'react'

export const isAdmin = (
  teams: Team[],
  platformConsoleTeamId: string,
  email?: string,
  owningTeam?: string | null
): boolean =>
  email === owningTeam ||
  teams.filter(({ teamId }: Team) => teamId === owningTeam || teamId === platformConsoleTeamId)
    .length > 0

export const setAnnouncementIds = (announcements: Announcement[] = []): Announcement[] =>
  announcements.map((announcement) => ({ ...announcement, id: createAnnouncementId(announcement) }))

/**
 * Allows components to listen for changes to browser location query params,
 * to show/hide the relevant UI elements when user navigates with Back/Forward.
 */
export const useReactSearch = () => {
  const [search, setSearch] = useState(window.location.search)
  const listenToPopstate = () => {
    setSearch(window.location.search)
  }
  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate)
    return () => {
      window.removeEventListener('popstate', listenToPopstate)
    }
  }, [])
  return search
}
