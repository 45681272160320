import { Field, Option, RenderFieldProps } from './types'
import { validation } from './validation'
import { Select as EdsSelect } from '@nike/eds'
import { Controller, ControllerRenderProps } from 'react-hook-form'

export const Select = ({ config, control }: RenderFieldProps): JSX.Element => (
  <Controller
    name={config.title}
    control={control}
    rules={validation(config.type, config.required, config.title)}
    defaultValue={config.defaultValue || []}
    render={({ field, fieldState: { error } }) => (
      <EdsSelect
        id={config.title}
        label={config.title}
        hasErrors={!!error}
        errorMessage={error?.message}
        options={config.options}
        required={config.required}
        subtitle={config?.subtitle}
        menuPosition='fixed'
        {...(field as ControllerRenderProps<Field<Option<string>[]>>)}
      />
    )}
  />
)
