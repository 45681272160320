import { ModalWrapper } from './ModalWrapper'
import { StatusDetails } from './StatusDetails'
import { UserAccessContext } from './UserAccessContext'
import styles from './accessRequest.styl'
import { TEXT_LABELS } from './consts'
import { FC, useContext, useState } from 'react'
import { createPortal } from 'react-dom'

interface ShowStatusDetailsButtonProps {
  disable?: boolean
  role?: string
}

export const ShowStatusDetailsButton: FC<ShowStatusDetailsButtonProps> = ({
  children,
  disable,
  role,
}) => {
  const { modalIsVisible, accessMethodsCount, portalRef } = useContext(UserAccessContext)
  const [showModal, setShowModal] = useState<boolean>(false)

  if (accessMethodsCount <= 1 || disable) {
    return <>{children}</>
  }

  const toggleDetailsModal = () => {
    setShowModal((val) => !val)
    modalIsVisible.current = !modalIsVisible.current
  }

  return (
    <>
      <div
        onClick={toggleDetailsModal}
        className={styles.statusDetails}
        title={TEXT_LABELS.showStatusDetails}
      >
        {children}
      </div>
      {!!portalRef.current &&
        showModal &&
        createPortal(
          <ModalWrapper onDismiss={toggleDetailsModal}>
            <StatusDetails role={role} />
          </ModalWrapper>,
          portalRef.current
        )}
    </>
  )
}
