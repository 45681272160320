import styles from '../accessRequest.styl'
import { TEXT_LABELS } from '../consts'
import { IDLockerAccessStatus } from './IDLockerAccessStatus'
import { Text } from '@nike/eds'

export interface RolesProps {
  roles: Array<{ adGroups: Array<string>; role: string }>
  techSolutionId: string
  accessToken: string
}

export const Roles = ({ roles, techSolutionId, accessToken }: RolesProps): JSX.Element => (
  <div className={styles.rolesContainer}>
    <Text font='body-2' className={styles.rolesDescription}>
      {TEXT_LABELS.rolesDescription}
    </Text>
    {roles.map(({ role }) => (
      <div className={styles.accessRow} key={role} data-testid={role}>
        <Text font='body-3' className={styles.roleName} title={role}>
          {role}:&nbsp;
        </Text>
        <IDLockerAccessStatus
          role={role}
          techSolutionId={techSolutionId}
          accessToken={accessToken}
        />
      </div>
    ))}
  </div>
)
