import { Link } from '../../components/Link'
import { TEXT_LABELS } from '../consts'
import { Button } from '@nike/eds'

interface CustomUrlProps {
  techSolutionUrl: string
  techSolutionName: string
  navigate?: (href: string) => void
}

export const CustomUrl = ({
  techSolutionUrl,
  navigate,
  techSolutionName,
}: CustomUrlProps): JSX.Element => (
  <Link navigate={navigate} href={techSolutionUrl}>
    <Button tabIndex={-1} size='small'>
      {TEXT_LABELS.launch} {techSolutionName}
    </Button>
  </Link>
)
