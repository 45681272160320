import { Snack, Snackbar } from '@nike/eds'
import { ReactNode, createContext, useCallback, useState } from 'react'

interface SnacksProps {
  children: ReactNode
}

type SnackStatus = 'info' | 'success' | 'warning' | 'error' | 'neutral'
export interface SnackOptions {
  id?: number | string
  message: string
  onDismiss?: (id: string) => void
  status?: SnackStatus
  autoDismissDuration?: number
}

export interface SnackContextProps {
  createSnack: (snackOptions: SnackOptions) => void
  snacks?: SnackOptions[]
}

// idgen for notification system
let SNACK_ID = 1
export const SnackbarContext = createContext<SnackContextProps>({
  createSnack: () => undefined,
  snacks: [],
})

export function Snacks({ children }: SnacksProps) {
  const [snacks, setSnacks] = useState<SnackOptions[]>([])

  function isSnackActive(id: number, prevSnacks: SnackOptions[]) {
    return prevSnacks.find((s) => s.id === id)
  }

  const createSnack = useCallback((options: SnackOptions) => {
    SNACK_ID++
    const id = SNACK_ID
    const newSnack = { ...options, id }
    setSnacks((prevSnacks) => [...prevSnacks, newSnack])
  }, [])

  const dismissSnack = useCallback((id: number) => {
    setSnacks((prevSnacks) =>
      isSnackActive(id, prevSnacks)
        ? [...prevSnacks.filter((snack) => snack.id !== id)]
        : prevSnacks
    )
  }, [])

  return (
    <SnackbarContext.Provider value={{ snacks, createSnack }}>
      <Snackbar>
        {snacks.map((snack: SnackOptions) => (
          // @ts-expect-error
          <Snack key={snack.id} autoDismissDuration={6000} onDismiss={dismissSnack} {...snack}>
            {snack.message}
          </Snack>
        ))}
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}
