export type Query<I = void> = {
  operationName: string
  variables: {
    id?: string
    techSolutionId?: string
    accessType?: string
    input?: I
    action?: string
    role?: string
    customForm?: string
  }
  query: string
}

export const getTechSolutionQuery = (techSolutionId: string): Query => ({
  operationName: 'techSolution',
  variables: {
    id: techSolutionId,
  },
  query: `
    query techSolution($id: ID!) {
      techSolution(id: $id) {
        id
        name
        description
        title
        url
        slug
        supportService
        slack
        devPortalProjectName
        accessType
        integratedPath
        releaseNotes {
          id
          title
          markdown
          dateReleased
        }
        templates
        trainingResources {
          name
          description
          url
        }
        announcements {
          markdown
          type
          releasedOn
        }
        lifecycle {
          status
        }
        userAccessSettingsV2 {
          snow {
            description
            formType
            externalLink
            customForm {
              data
              description
              enable
            }
          }
          jira {
            description
            projectKey
            labels
            customForm {
              data
              description
              enable
            }
          }
          idLocker {
            roles {
              adGroups
              role
            }
          }
        }
        owningTeam
        owningTeamName
        roadmapLink
        health {
          status
          updatedOn
        }
        contacts {
          link {
            text
            url
          }
          type
        }
        uptime {
          current {
            region
            value
          }
          quarters {
            quarter
            year
            regions {
              region
              value
            }
          }
        }
        metricWidgets {
          id
          dataSourceURL
          dataQuery
          name
          description
          tags
          lifecycleState
          type
          maintainerEmail
          maintainerSlack
          colorPalette
          moreInfoURL
          scope
          refreshFrequency
          examples {
            name
            description
            data
          }
          techSolutionIds
          updatedBy
          updatedOn
          timezoneSupport
        }
        videos {
          id
          name
          url
        }
      }
      regionalHealth(id: $id) {
        status
        updatedOn
        name
        region
      }
      relatedTechSolutions(id: $id) {
        id
        name
        description
        slug
        integratedPath
        accessType
        health {
          status
          updatedOn
        }
      }
    }
`,
})

export const getReleaseNotesQuery = (techSolutionId: string): Query => ({
  operationName: 'techSolution',
  variables: {
    id: techSolutionId,
  },
  query: `
    query techSolution($id: ID!) {
      techSolution(id: $id) {
        name
        releaseNotes {
          title
          markdown
          dateReleased
        }
      }
    }
`,
})

export const getUserAccessStatusQuery = (techSolutionId: string, role?: string): Query => ({
  operationName: 'getUserAccessStatusForTechSolution',
  variables: {
    techSolutionId,
    role,
  },
  query: `
  query getUserAccessStatusForTechSolution($techSolutionId: ID!, $role: String) {
    getUserAccessStatusForTechSolution(techSolution: $techSolutionId, role: $role) {
      status
      jira
      idLocker
      snow
    }
  }
`,
})

export const getUserQuery = (techSolutionId: string): Query => ({
  operationName: 'user',
  variables: {
    techSolutionId,
  },
  query: `
  query user($techSolutionId: String!) {
    user(techSolutionId: $techSolutionId) {
      favorites {
        type
        id
        slug
        name
      }
      newReleaseNotesCount
      releaseNotesLastSeen
    }
  }
`,
})

export const subscriptionForUser = (techSolutionId: string): Query => ({
  operationName: 'subscriptionForUser',
  variables: {
    techSolutionId,
  },
  query: `
    query subscriptionForUser($techSolutionId: ID!) {
      subscriptionForUser(techSolutionId: $techSolutionId) {
        email
        techSolutionId
      }
    }
  `,
})

export const platformsData = (): Query => ({
  operationName: 'platforms',
  variables: {},
  query: `
    query platforms {
      platforms {
        name
        slug
        techSolutions {
            id
        }
      }
    }
  `,
})
