// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csn39F3e_kLq04yewnOl {
  width: 640px;
  padding: var(--eds-space-16) 0;
}
.ExtrKtwtsBhpwoCC3O2I {
  margin-bottom: var(--eds-space-24);
  padding: 2px;
}
.ExtrKtwtsBhpwoCC3O2I > div {
  margin-bottom: var(--eds-space-4);
}
.G5XBagztgMi73LxoSy3V {
  margin-bottom: var(--eds-space-16);
}
`, "",{"version":3,"sources":["webpack://./src/AccessRequest/custom-form/formView.module.styl"],"names":[],"mappings":"AAAA;EACE,YAAM;EACN,8BAAQ;AACV;AACA;EACE,kCAAc;EACd,YAAQ;AACV;AACE;EACE,iCAAc;AAClB;AACA;EACE,kCAAc;AAChB","sourcesContent":[".modalBody\n  width 640px\n  padding var(--eds-space-16) 0\n\n.fieldWrapper\n  margin-bottom var(--eds-space-24)\n  padding 2px\n\n  > div\n    margin-bottom var(--eds-space-4)\n\n.formDescription\n  margin-bottom var(--eds-space-16)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": `csn39F3e_kLq04yewnOl`,
	"fieldWrapper": `ExtrKtwtsBhpwoCC3O2I`,
	"formDescription": `G5XBagztgMi73LxoSy3V`
};
export default ___CSS_LOADER_EXPORT___;
