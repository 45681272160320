import { AdGroupSelect } from './AdGroupSelect'
import { Checkbox } from './Checkbox'
import { MultiEmail } from './MultiEmail'
import { Radio } from './Radio'
import { Select } from './Select'
import { Text } from './Text'
import { TextArea } from './TextArea'
import { Toggle } from './Toggle'
import { PRIORITY_OPTIONS } from './consts'
import { ControlInput, ControlTypes, Fields } from './types'
import { Control, ValidateResult } from 'react-hook-form'

interface FieldResolverProps {
  config: ControlInput<ControlTypes>
  control: Control<Fields>
  error?: ValidateResult
  accessToken: string
}

export const FieldResolver = ({
  config,
  control,
  accessToken,
}: FieldResolverProps): JSX.Element | null => {
  if (!config) {
    return null
  }

  switch (config.type) {
    case ControlTypes.EMAIL:
    case ControlTypes.SINGLE_LINE:
    case ControlTypes.URL:
      return <Text config={config} control={control} />
    case ControlTypes.NUMBER:
      return <Text config={config} control={control} isNumber />
    case ControlTypes.MULTI_LINE:
      return <TextArea config={config} control={control} />
    case ControlTypes.MULTI_EMAIL:
      return <MultiEmail config={config} control={control} />
    case ControlTypes.SELECT:
      return config.options?.length >= 6 ? (
        <Select config={config} control={control} />
      ) : (
        <Radio config={config} control={control} />
      )
    case ControlTypes.CHECKBOX:
      return <Checkbox config={config} control={control} />
    case ControlTypes.TOGGLE:
      return <Toggle config={config} control={control} />
    case ControlTypes.PRIORITY:
      return <Select config={{ ...config, options: PRIORITY_OPTIONS }} control={control} />
    case ControlTypes.AD_GROUP:
      return <AdGroupSelect config={config} control={control} accessToken={accessToken} />
    default:
      return null
  }
}
