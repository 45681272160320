import { FieldResolver } from './fields/FieldResolver'
import { ControlInput, ControlTypes, Fields, FormInput } from './fields/types'
import styles from './formView.module.styl'
import { Button, Modal, Text } from '@nike/eds'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

interface FormViewProps {
  data: FormInput<ControlTypes>
  description: string
  onSubmit: (data?: Fields) => void
  onDismiss: () => void
  accessToken: string
}

export const FormView = ({
  data,
  description,
  onSubmit,
  onDismiss,
  accessToken,
}: FormViewProps): JSX.Element => {
  const { handleSubmit, reset, control } = useForm<Fields>({
    mode: 'onBlur',
    defaultValues: prepareData(data),
  })

  useEffect(() => {
    reset(prepareData(data))
  }, [data, reset])

  return (
    <form
      onSubmit={(event) => {
        event.stopPropagation()
        event.preventDefault()
        void handleSubmit(onSubmit)()
      }}
    >
      <Modal
        headerSlot='Request Access'
        isOpen
        onDismiss={onDismiss}
        data-testid='request-form-modal'
        displayCheckOverride
        footerSlot={
          <Button type='submit' size='medium'>
            Send Request
          </Button>
        }
      >
        <div className={styles.modalBody}>
          <Text font='body-2' className={styles.formDescription}>
            {description}
          </Text>
          {data.controls.map((field) => (
            <div className={styles.fieldWrapper} key={field.title}>
              <FieldResolver config={field} control={control} accessToken={accessToken} />
            </div>
          ))}
        </div>
      </Modal>
    </form>
  )
}

const prepareData = (data: FormInput<ControlTypes>): FieldValues => {
  const result: FieldValues = {}
  data.controls.forEach((field: ControlInput<ControlTypes> | null) => {
    if (field) {
      result[field.title] = field.defaultValue || ''
    }
  })

  return result
}
