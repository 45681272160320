// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SXSTyIZCZFVXCa4uxcAy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--eds-radii-8);
  background-color: var(--eds-color-white);
  padding: var(--eds-space-8) var(--eds-space-24);
  min-height: 80px;
}
.SXSTyIZCZFVXCa4uxcAy > div {
  width: 100%;
}
.SXSTyIZCZFVXCa4uxcAy .G6kCD3lyhghgIQcw33HX {
  margin: 0px;
}
.TFwMUFnBO2eyY7KaHWgm {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.TFwMUFnBO2eyY7KaHWgm .O5b1y93GH8jrZkxdGw_M {
  margin-top: 12px;
  color: var(--eds-color-text-secondary);
}
.TFwMUFnBO2eyY7KaHWgm .QPt6nagEJG3XmNPCDr8a {
  position: relative;
  top: 2px;
  margin-left: 8px;
  padding: 4px;
  color: var(--eds-color-text-secondary);
}
.TFwMUFnBO2eyY7KaHWgm ._qShw_8LnJ551sFRvDCE {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 53px;
  height: 24px;
  padding: 0px 12px;
  border-radius: 24px;
  background-color: var(--eds-color-teal-60);
  color: var(--eds-color-background-default);
}
.ZflCUn_ppXQWktUbyesK {
  color: var(--eds-color-text-default);
}
.HnL_P96lUN5lFGvcI08F {
  margin: 4px 0 3px;
}
.HnL_P96lUN5lFGvcI08F > :first-child {
  margin-top: 0;
}
.HnL_P96lUN5lFGvcI08F p {
  margin: 15px 0;
}
.HnL_P96lUN5lFGvcI08F a {
  font-size: 14px;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/HomePage/tabComponents/releaseNotes.module.styl"],"names":[],"mappings":"AAEA;EACE,WAAM;EACN,aAAQ;EACR,mBAAY;EACZ,8BAAgB;EAChB,iCAAc;EACd,wCAAiB;EACjB,+CAAQ;EACR,gBAAY;AADd;AAGE;EACE,WAAM;AADV;AAGE;EACE,WAAO;AADX;AAGA;EACE,eAAW;EACX,WAAM;EACN,aAAQ;EACR,8BAAgB;EAChB,uBAAY;AADd;AAEE;EACE,gBAAW;EACX,sCAAM;AAAV;AACE;EACE,kBAAU;EACV,QAAK;EACL,gBAAa;EACb,YAAS;EACT,sCAAM;AACV;AAAE;EACI,aAAQ;EACR,mBAAY;EACZ,yBAAgB;EAChB,WAAM;EACN,YAAO;EACP,iBAAQ;EACR,mBAAc;EACd,0CAAiB;EACjB,0CAAM;AAEZ;AAAA;EACE,oCAAO;AAET;AAAA;EACE,iBAAO;AAET;AADE;EACE,aAAW;AAGf;AAFE;EACE,cAAO;AAIX;AAHE;EACE,eAAU;EACV,0BAAgB;AAKpB","sourcesContent":["@require '../../styles/variables'\n\n.releaseNotes\n  width 100%\n  display flex\n  align-items center\n  justify-content space-between\n  border-radius var(--eds-radii-8)\n  background-color var(--eds-color-white)\n  padding var(--eds-space-8) var(--eds-space-24)\n  min-height: 80px\n\n  > div\n    width 100%\n\n  .divider\n    margin 0px\n\n.releaseNoteHeader\n  margin-top 5px\n  width 100%\n  display flex\n  justify-content space-between\n  align-items flex-start\n  .dateReleased      \n    margin-top 12px\n    color var(--eds-color-text-secondary)\n  .copyLink\n    position: relative\n    top: 2px\n    margin-left: 8px;\n    padding: 4px;\n    color var(--eds-color-text-secondary)\n  .newChip\n      display flex\n      align-items center\n      justify-content flex-end\n      width 53px\n      height 24px\n      padding 0px 12px\n      border-radius 24px\n      background-color var(--eds-color-teal-60)\n      color var(--eds-color-background-default)\n\n.releaseNoteTitle\n  color: var(--eds-color-text-default)\n\n.releaseNoteBody\n  margin 4px 0 3px\n  > :first-child\n    margin-top 0\n  p\n    margin 15px 0\n  a\n    font-size 14px\n    text-decoration underline"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"releaseNotes": `SXSTyIZCZFVXCa4uxcAy`,
	"divider": `G6kCD3lyhghgIQcw33HX`,
	"releaseNoteHeader": `TFwMUFnBO2eyY7KaHWgm`,
	"dateReleased": `O5b1y93GH8jrZkxdGw_M`,
	"copyLink": `QPt6nagEJG3XmNPCDr8a`,
	"newChip": `_qShw_8LnJ551sFRvDCE`,
	"releaseNoteTitle": `ZflCUn_ppXQWktUbyesK`,
	"releaseNoteBody": `HnL_P96lUN5lFGvcI08F`
};
export default ___CSS_LOADER_EXPORT___;
