import { TechSolution } from '../../util/api/pc'
import { Link, Text } from '@nike/eds'

const OWNER_MSG = {
  ownerTeamMissing: '(no group associated yet)',
  owningTeamNameMissing: 'Owner Not Found',
} as const

interface OwnerProps extends Pick<TechSolution, 'owningTeam' | 'owningTeamName'> {
  devportalUrl: string
}

export const Owner = ({ devportalUrl, owningTeam, owningTeamName }: OwnerProps): JSX.Element => {
  if (!owningTeam) {
    return <Text font='subtitle-1'>{OWNER_MSG.ownerTeamMissing}</Text>
  }

  if (!owningTeamName) {
    return <Text font='subtitle-1'>{OWNER_MSG.owningTeamNameMissing}</Text>
  }

  if (owningTeamName.includes('@nike.com')) {
    return <Link href={`mailto:${owningTeamName}`}>{owningTeamName}</Link>
  } else {
    return (
      <Link target='_blank' href={`${devportalUrl}/teams/${owningTeamName}`}>
        {owningTeamName}
      </Link>
    )
  }
}
