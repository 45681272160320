import { ControlInput, ControlTypes, FieldValue, Fields } from './types'
import { RegisterOptions } from 'react-hook-form'

export const emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const urlValidation =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

type ValidationRules = Omit<
  RegisterOptions<Fields, string>,
  'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
>

type RequiredValidationRule = {
  required: (values: FieldValue) => string | undefined
}
export const validation = (
  type: ControlTypes,
  required: boolean,
  title: string
): ValidationRules => {
  const validationRules: ValidationRules = {}

  if (required) {
    validationRules.required = {
      value: true,
      message: `${title} is required`,
    }
  }

  switch (type) {
    case ControlTypes.EMAIL:
      validationRules.validate = (value: FieldValue) => {
        if (value && !emailValidation.test(value as string)) {
          return 'Invalid email address'
        }

        return undefined
      }
      break
    case ControlTypes.URL:
      validationRules.validate = (value: FieldValue) => {
        if (value && !urlValidation.test(value as string)) {
          return 'Invalid url'
        }

        return undefined
      }
      break
  }

  return validationRules
}

export const multiEmailValidation = (
  config: ControlInput<ControlTypes>
): RequiredValidationRule => ({
  required: (values: FieldValue) => {
    let errorMessage: string | undefined

    if (
      config.required &&
      (Array.isArray(values) || typeof values === 'string') &&
      !values.length
    ) {
      errorMessage = `${config.title} is required.`
    }
    if (Array.isArray(values)) {
      values.forEach((value) => {
        if (typeof value !== 'string' && !emailValidation.test(value.value)) {
          errorMessage += `${value.value} is not valid email address. `
        }
      })
    }

    return errorMessage
  },
})

export const adGroupValidation = (config: ControlInput<ControlTypes>): RequiredValidationRule => ({
  required: (values: FieldValue) => {
    if (
      config.required &&
      (Array.isArray(values) || typeof values === 'string') &&
      !values?.length
    ) {
      return `${config.title} is required.`
    }

    return undefined
  },
})
