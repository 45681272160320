// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OVud1rmtVUndTmieuR03 {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 35px;
}
.OVud1rmtVUndTmieuR03 .oX28h4l8vWP78OMWGmXd {
  cursor: pointer;
  display: flex;
  gap: 10px;
  transition: opacity 200ms;
}
.OVud1rmtVUndTmieuR03 .oX28h4l8vWP78OMWGmXd .bpjDujX19SsNYALEvHj9 {
  text-decoration: underline;
}
.OVud1rmtVUndTmieuR03 .oX28h4l8vWP78OMWGmXd:hover {
  opacity: 0.7;
}
.M5VLjtC114PTQI7Sjdjp div {
  padding: 2px;
}
.M5VLjtC114PTQI7Sjdjp div svg {
  width: 11px;
  height: 11px;
}
.Rm_K297CYK1goAA4GaXv {
  display: flex;
  align-items: center;
  margin: 5px 0;
  height: 40px;
  gap: 10px;
}
.Rm_K297CYK1goAA4GaXv .oKM6hfOYfnO8bAdG1vtl {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pll3O1WCopaeCtlQ_L6Y {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}
.pll3O1WCopaeCtlQ_L6Y .Ss8CfVPkdnbEPB6T9UCt {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.J6z87L1o0pFCm1OpjH0p .eds-popover {
  display: block;
  padding: var(--eds-space-32);
}
.oSYevFjtF5mZ83vW0n5p .eds-popover {
  display: none;
}
.QvF7iBnGDyt2jauRkV75 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.M8KOMp9fqPK9xNxYX_5p .eds-modal__content > div {
  overflow-x: hidden;
}
.RGIPFNtfnCE40dzEnNG2 {
  display: flex;
  align-items: center;
  height: 40px;
}
.RGIPFNtfnCE40dzEnNG2 .oKM6hfOYfnO8bAdG1vtl {
  width: 400px;
}
.XvXlRvpJZeUXJ_wcrmHn {
  margin: 0 var(--eds-space-16) var(--eds-space-16) 0;
}
`, "",{"version":3,"sources":["webpack://./src/AccessRequest/accessRequest.styl"],"names":[],"mappings":"AAAA;EACE,aAAQ;EACR,mBAAY;EACZ,SAAI;EACJ,YAAO;AACT;AACE;EACE,eAAO;EACP,aAAQ;EACR,SAAI;EACJ,yBAAW;AACf;AACI;EACE,0BAAgB;AACtB;AACI;EACE,YAAQ;AACd;AAEE;EACE,YAAQ;AAAZ;AACI;EACE,WAAM;EACN,YAAO;AACb;AACA;EACE,aAAQ;EACR,mBAAY;EACZ,aAAO;EACP,YAAO;EACP,SAAI;AACN;AACE;EACE,YAAM;EACN,mBAAY;EACZ,gBAAS;EACT,uBAAc;AAClB;AACA;EACE,8BAAgB;EAChB,uBAAY;EACZ,aAAQ;AACV;AACE;EACE,aAAQ;EACR,sBAAe;EACf,aAAQ;AACZ;AAGI;EACE,cAAQ;EACR,4BAAQ;AADd;AAKI;EACE,aAAQ;AAHd;AAKA;EACE,aAAQ;EACR,sBAAe;EACf,aAAQ;AAHV;AAME;EACE,kBAAW;AAJf;AAMA;EACE,aAAQ;EACR,mBAAY;EACZ,YAAO;AAJT;AAME;EACE,YAAM;AAJV;AAMA;EACE,mDAAO;AAJT","sourcesContent":[".accessWrapper\n  display flex\n  align-items center\n  gap 10px\n  height 35px\n\n  .statusDetails\n    cursor pointer\n    display flex\n    gap 10px\n    transition opacity 200ms\n\n    .statusText\n      text-decoration underline\n\n    &:hover\n      opacity .7\n\n.tooltipIcon\n  div\n    padding 2px\n    svg\n      width 11px\n      height 11px\n      \n.accessRow\n  display flex\n  align-items center\n  margin 5px 0\n  height 40px\n  gap 10px\n\n  .roleName\n    width 200px\n    white-space nowrap\n    overflow hidden\n    text-overflow ellipsis\n\n.popoverBody\n  justify-content space-between\n  align-items flex-start\n  display flex\n\n  .popoverContent\n    display flex\n    flex-direction column\n    row-gap 10px\n\n.popoverOpen\n  :global\n    .eds-popover\n      display block\n      padding var(--eds-space-32)\n\n.popoverClosed\n  :global\n    .eds-popover\n      display none\n\n.modalBody\n  display flex\n  flex-direction column\n  row-gap 10px\n\n.modalContainer\n  :global(.eds-modal__content) > div\n    overflow-x hidden\n\n.accessContainer\n  display flex\n  align-items center\n  height 40px\n\n  .roleName\n    width 400px\n\n.rolesDescription\n  margin 0 var(--eds-space-16) var(--eds-space-16) 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accessWrapper": `OVud1rmtVUndTmieuR03`,
	"statusDetails": `oX28h4l8vWP78OMWGmXd`,
	"statusText": `bpjDujX19SsNYALEvHj9`,
	"tooltipIcon": `M5VLjtC114PTQI7Sjdjp`,
	"accessRow": `Rm_K297CYK1goAA4GaXv`,
	"roleName": `oKM6hfOYfnO8bAdG1vtl`,
	"popoverBody": `pll3O1WCopaeCtlQ_L6Y`,
	"popoverContent": `Ss8CfVPkdnbEPB6T9UCt`,
	"popoverOpen": `J6z87L1o0pFCm1OpjH0p`,
	"popoverClosed": `oSYevFjtF5mZ83vW0n5p`,
	"modalBody": `QvF7iBnGDyt2jauRkV75`,
	"modalContainer": `M8KOMp9fqPK9xNxYX_5p`,
	"accessContainer": `RGIPFNtfnCE40dzEnNG2`,
	"rolesDescription": `XvXlRvpJZeUXJ_wcrmHn`
};
export default ___CSS_LOADER_EXPORT___;
