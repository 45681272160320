import { StatusIndicator } from '../components/StatusIndicator'
import { RelatedTechSolution } from '../util/api/pc'
import { ConditionalLink } from './ConditionalLink'
import styles from './essentialCard.styl'
import { Card, Text } from '@nike/eds'

interface EssentialCardProps extends RelatedTechSolution {
  navigate?: (href: string) => void
}

const statusMap = {
  pass: 'Operational',
  fail: 'Error',
  warn: 'Warning',
} as const

export const EssentialCard = ({
  name,
  description,
  slug,
  integratedPath,
  health,
  accessType,
  navigate,
}: EssentialCardProps): JSX.Element | null => {
  if (!name || !description) {
    return null
  }

  return (
    <Card className={styles.essentialCard}>
      <div className={styles.content}>
        <Text className={styles.name} as='h2' font='title-6'>
          <ConditionalLink
            accessType={accessType}
            integratedPath={integratedPath}
            slug={slug}
            navigate={navigate}
          >
            {name}
          </ConditionalLink>
        </Text>
        <Text className={styles.description} as='p' font='body-3'>
          {description}
        </Text>
        {health?.status && (
          <div className={styles.health}>
            <StatusIndicator className={styles.statusIcon} status={health.status} />
            <Text className={styles.description} as='span' font='body-3'>
              {statusMap[health.status]}
            </Text>
          </div>
        )}
      </div>
    </Card>
  )
}
