import { getConfig } from '../../config'
import { RequestStatus, getUserAccessStatus, putUserAccessRequest } from '../../util/api/pc'
import { Status } from '../Status'
import styles from '../accessRequest.styl'
import { REQUEST_STATUS, TEXT_LABELS, USER_ACCESS_TYPE } from '../consts'
import { useUserAccessNarkLog } from '../utils'
import { Spinner } from '@nike/eds'
import { useEffect, useState } from 'react'

export interface SnowTicketStatusProps {
  accessToken: string
  techSolutionId: string
}

export const SnowTicketStatus = ({
  accessToken,
  techSolutionId,
}: SnowTicketStatusProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(true)
  const [status, setStatus] = useState<RequestStatus>()
  const [isAccessRequest, setIsAccessRequest] = useState(false)
  const { platformConsoleApiUrl } = getConfig()
  const { nark } = useUserAccessNarkLog(techSolutionId, USER_ACCESS_TYPE.snow)

  const fetchStatus = async () => {
    try {
      setIsLoading(true)
      const { userAccessStatus, errors } = await getUserAccessStatus({
        platformConsoleApiUrl,
        techSolutionId,
        accessToken,
      })
      if (errors) {
        setStatus(REQUEST_STATUS.failed)
        nark(errors, 'getUserAccessStatus')
      } else {
        setStatus(userAccessStatus)
      }
    } catch (error) {
      console.error('Error fetching status', error)
      nark(error, 'getUserAccessStatus')
    } finally {
      setIsLoading(false)
    }
  }

  const requestAccess = async () => {
    setIsAccessRequest(true)
    setIsLoading(true)

    try {
      const { userAccessStatus, errors } = await putUserAccessRequest({
        platformConsoleApiUrl,
        techSolutionId,
        accessToken,
      })
      if (errors) {
        setStatus(REQUEST_STATUS.failed)
        nark(errors)
      } else {
        setStatus(userAccessStatus)
      }
    } catch (error) {
      console.error('Error requesting access', error)
      nark(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void fetchStatus()
  }, [techSolutionId])

  if (isLoading) {
    return (
      <div className={styles.accessWrapper} data-testid='ticket-status'>
        <Spinner />
      </div>
    )
  }

  return (
    <Status
      status={status}
      requestAccess={requestAccess}
      disclaimer={TEXT_LABELS.ticketStatusDisclaimer}
      isAccessRequest={isAccessRequest}
      fetchStatus={fetchStatus}
    />
  )
}
