export type EnvType = 'prod' | 'prod-stage' | 'nonprod' | 'nonprod-stage' | 'dev'

type EnvUrlType = '-dev' | '-nonprod-stage' | '' | '-nonprod' | '-stage'

interface Nark {
  platformId: string
  url: string
}

interface BaseConfig {
  aegisApiUrl: string
  consoleSlackChannel: string
  devportalUrl: string
  devportalApiUrl: string
  docsUrl: string
  env: EnvType
  nark: Nark
  platformConsoleApiUrl: string
  platformConsoleTeamId: string
  platformConsoleUrl: string
  qualtricsId?: string
  teamsApiUrl: string
  damApiUrl: string
}

export interface AppConfig extends BaseConfig {
  platformConsoleTechSolutionId: string
}

const platformConsoleTechSolutionId = '20d80335-a38e-42ac-b9ae-593978881a4c'

const platformConsoleApiUrl = (env: EnvUrlType) =>
  process.env.REACT_APP_API || `https://api${env}-console.platforms.nike.com/graphql`

const platformConsoleUrl = (env: EnvUrlType) => `https://console${env}.platforms.nike.com`

const devPlatformConsoleTeamId = 'vWgsul6gSMWVlS2DSVpwEw'

const prodPlatformConsoleTeamId = 'c33639e6-59f3-4ae7-9006-515d5d1972fb'

const narkPreprod: Nark = {
  platformId: '==41iqF9u0PANj5EPl0Eixb',
  url: 'https://api-preprod.nark.nikecloud.com/v1',
}

const narkProd: Nark = {
  platformId: '==w2rMyMqTG0Df-M6_x02Vi',
  url: 'https://api.nark.nikecloud.com/v1',
}

const dev: BaseConfig = {
  platformConsoleApiUrl: platformConsoleApiUrl('-dev'),
  platformConsoleUrl: platformConsoleUrl('-dev'),
  teamsApiUrl: 'https://api-teams.preprod.niketech.com/v1/teams/',
  platformConsoleTeamId: devPlatformConsoleTeamId,
  nark: narkPreprod,
  consoleSlackChannel: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/qa/users',
  docsUrl: 'https://docs-nonprod.platforms.nike.com',
  devportalUrl: 'https://console-nonprod.platforms.nike.com/developer',
  devportalApiUrl: 'https://api-developer.preprod.niketech.com/v1',
  damApiUrl: 'https://api.preprod.dam.nike.com/v1',
  env: 'dev',
}

const nonprodStage: BaseConfig = {
  platformConsoleApiUrl: platformConsoleApiUrl('-nonprod-stage'),
  platformConsoleUrl: platformConsoleUrl('-nonprod-stage'),
  teamsApiUrl: 'https://api-teams.preprod.niketech.com/v1/teams/',
  platformConsoleTeamId: devPlatformConsoleTeamId,
  nark: narkPreprod,
  consoleSlackChannel: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/qa/users',
  docsUrl: 'https://docs-nonprod.platforms.nike.com',
  devportalUrl: 'https://console-nonprod.platforms.nike.com/developer',
  devportalApiUrl: 'https://api-developer.preprod.niketech.com/v1',
  damApiUrl: 'https://api.preprod.dam.nike.com/v1',
  env: 'nonprod-stage',
}

const nonprod: BaseConfig = {
  platformConsoleApiUrl: platformConsoleApiUrl('-nonprod'),
  platformConsoleUrl: platformConsoleUrl('-nonprod'),
  teamsApiUrl: 'https://api-teams.preprod.niketech.com/v1/teams/',
  platformConsoleTeamId: devPlatformConsoleTeamId,
  nark: narkPreprod,
  consoleSlackChannel: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
  qualtricsId: 'ZN_cSHoFS1kFrLNGKi',
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/qa/users',
  docsUrl: 'https://docs-nonprod.platforms.nike.com',
  devportalUrl: 'https://console-nonprod.platforms.nike.com/developer',
  devportalApiUrl: 'https://api-developer.preprod.niketech.com/v1',
  damApiUrl: 'https://api.preprod.dam.nike.com/v1',
  env: 'nonprod',
}

const prodStage: BaseConfig = {
  platformConsoleApiUrl: platformConsoleApiUrl('-stage'),
  platformConsoleUrl: platformConsoleUrl('-stage'),
  teamsApiUrl: 'https://api-teams.niketech.com/v1/teams/',
  platformConsoleTeamId: prodPlatformConsoleTeamId,
  nark: narkPreprod,
  consoleSlackChannel: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/prod/users',
  docsUrl: 'https://docs-nonprod.platforms.nike.com',
  devportalUrl: 'https://console-nonprod.platforms.nike.com/developer',
  devportalApiUrl: 'https://api-developer.niketech.com/prod/v1',
  damApiUrl: 'https://api.preprod.dam.nike.com/v1',
  env: 'prod-stage',
}

const prod: BaseConfig = {
  platformConsoleApiUrl: platformConsoleApiUrl(''),
  platformConsoleUrl: platformConsoleUrl(''),
  teamsApiUrl: 'https://api-teams.niketech.com/v1/teams/',
  platformConsoleTeamId: prodPlatformConsoleTeamId,
  nark: narkProd,
  consoleSlackChannel: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
  qualtricsId: 'ZN_cSHoFS1kFrLNGKi',
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/prod/users',
  docsUrl: 'https://docs.platforms.nike.com',
  devportalUrl: 'https://console.platforms.nike.com/developer',
  devportalApiUrl: 'https://api-developer.niketech.com/prod/v1',
  damApiUrl: 'https://api.dam.nike.com/v1',
  env: 'prod',
}

let appConfig: AppConfig = dev as AppConfig

export const setConfig = (env?: EnvType): void => {
  let config = dev
  const locationHasEnvURL = (envUrl: string) =>
    window.location.origin.includes(`//${envUrl}.platforms.nike.com`)

  if (env === 'prod' || locationHasEnvURL('console')) {
    config = prod
  } else if (env === 'prod-stage' || locationHasEnvURL('console-stage')) {
    config = prodStage
  } else if (env === 'nonprod' || locationHasEnvURL('console-nonprod')) {
    config = nonprod
  } else if (env === 'nonprod-stage' || locationHasEnvURL('console-nonprod-stage')) {
    config = nonprodStage
  } else if (env === 'dev' || locationHasEnvURL('console-dev')) {
    config = dev
  } else if (window.location.origin.includes('localhost')) {
    config = dev
  } else {
    config = prod
  }

  appConfig = {
    ...config,
    platformConsoleTechSolutionId,
  }
}

export const getConfig = (): AppConfig => appConfig
