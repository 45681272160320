// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gnAzq3nUGGBAVQlNeh2r {
  max-width: 800px;
  min-width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/HomePage/tabComponents/releaseNoteModal.module.styl"],"names":[],"mappings":"AAAA;EACE,gBAAW;EACX,gBAAW;AACb","sourcesContent":[".modalContainer\n  max-width: 800px;\n  min-width: 600px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `gnAzq3nUGGBAVQlNeh2r`
};
export default ___CSS_LOADER_EXPORT___;
