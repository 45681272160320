export const REQUEST_STATUS = {
  notConfigured: 'notConfigured',
  available: 'available',
  pending: 'pending',
  declined: 'declined',
  approved: 'approved',
  failed: 'failed',
} as const

// based on UserAccessType from '../util/api/pc'
export const USER_ACCESS_TYPE = {
  okta: 'okta',
  idlocker: 'idlocker',
  snow: 'snow',
  jira: 'jira',
} as const

// based on AccessType from '../util/api/pc'
export const PLATFORM_ACCESS_TYPE = {
  integrated: 'INTEGRATED',
  divested: 'DIVESTED',
  link: 'LINK',
  steps: 'STEPS',
} as const

export const TEXT_LABELS = {
  accessDetails: 'Access Details',
  accessDeclined: 'Access declined',
  accessGranted: 'Access granted',
  launch: 'Launch',
  requestAccess: 'Request Access',
  requestSnow: 'Request Access via Service Now',
  requestFailed: 'Request failed',
  statusCheckFailed: 'Access status check failed',
  requestPending: 'Access request pending',
  resendRequest: 'Resend',
  requestAvailable: 'Request available',
  retry: 'Retry',
  rolesDescription: 'There are following access roles available in this tech solution.',
  showAccessOptions: 'Show access options',
  ticketStatusDisclaimer:
    "This is the request ticket's status. If the ticket is resolved, but you still don't have access, please contact the Tech Solution admin",
  idLocker: (role: string) => `IDLocker request status for ${role} role`,
  jira: 'Jira ticket status',
  snow: 'Service Now ticket status',
  showStatusDetails: 'Show status details',
  statusesModal: 'Status details',
  statusesDescription: (accessMethodsCount: number) =>
    `Getting access to this Tech Solution consists of ${accessMethodsCount} steps. The statuses of each of them are below`,
} as const

export const SNOW_FORM_TYPE = {
  link: 'link',
  cse: 'cse',
} as const
