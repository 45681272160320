import { UserAccessContext } from '../UserAccessContext'
import { FormView } from '../custom-form/FormView'
import { ControlTypes, Fields, FormInput } from '../custom-form/fields/types'
import { useContext } from 'react'

interface JiraCustomFormProps {
  onSubmit: (data?: Fields) => void
  accessToken: string
  onDismiss(): void
}

export const JiraCustomForm = ({
  onSubmit,
  onDismiss,
  accessToken,
}: JiraCustomFormProps): JSX.Element | null => {
  const userAccess = useContext(UserAccessContext)

  if (!userAccess.jira?.customForm?.enable) {
    return null
  }

  const customForm: FormInput<ControlTypes> = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    controls:
      (
        JSON.parse(
          userAccess.jira?.customForm?.data || '{"controls":[]}'
        ) as FormInput<ControlTypes>
      )?.controls || [],
  }

  return (
    <FormView
      onDismiss={onDismiss}
      data={customForm}
      description={userAccess.jira?.customForm?.description || ''}
      onSubmit={onSubmit}
      accessToken={accessToken}
    />
  )
}
