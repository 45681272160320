import { PLATFORM_ACCESS_TYPE } from '../AccessRequest/consts'
import { Link } from '../components/Link'
import { IntegratedPath, AccessType } from '../util/api/pc'
import { AnchorHTMLAttributes, FC } from 'react'

interface ConditionalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  integratedPath: IntegratedPath | null
  accessType: AccessType
  slug?: string
  navigate?: (href: string) => void
}

export const ConditionalLink: FC<ConditionalLinkProps> = ({
  children,
  integratedPath,
  accessType,
  slug = '',
  navigate,
  ...rest
}) => {
  const isLink = slug || integratedPath

  if (isLink) {
    return accessType === PLATFORM_ACCESS_TYPE.integrated && integratedPath ? (
      <a rel='noreferrer' href={`${window.location.origin}/${integratedPath}`} {...rest}>
        {children}
      </a>
    ) : (
      <Link href={`/${slug}`} navigate={navigate} {...rest}>
        {children}
      </Link>
    )
  }

  return <>{children}</>
}
