import { Link } from '../components/Link'
import { StatusIndicator } from '../components/StatusIndicator'
import { RegionalHealth, Uptime } from '../util/api/pc'
import { MAPPED_REGIONS } from '../util/regions'
import { UptimeDetails } from './UptimeDetails'
import styles from './healthStatus.styl'
import { getStatus, getHealthTitle } from './util'
import { Text, Popover } from '@nike/eds'
import moment from 'moment'
import { useState } from 'react'

export interface HealthStatusProps {
  slug: string
  regionalHealth: RegionalHealth[]
  uptime?: Uptime | null
  techSolutionName: string
  navigate?: (href: string) => void
}

export const HealthStatus = ({
  slug,
  regionalHealth,
  techSolutionName,
  uptime,
  navigate,
}: HealthStatusProps): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const updatedOn =
    regionalHealth?.reduce(
      (newest, status) => (status.updatedOn > newest ? status.updatedOn : newest),
      regionalHealth[0]?.updatedOn
    ) ?? null
  const regionalStatus = getStatus(regionalHealth)
  const shouldShowRegionalHealthInfo = !!regionalHealth?.length

  const statusText = (
    <Text
      onMouseEnter={() => {
        setIsPopoverVisible(true)
      }}
      className={styles.status}
      font='body-2'
      as='span'
    >
      {getHealthTitle(regionalHealth, techSolutionName)}
    </Text>
  )

  return (
    <div className={styles.health}>
      <div className={styles.iconRow}>
        <StatusIndicator status={regionalStatus} />
        {shouldShowRegionalHealthInfo ? (
          <Popover
            isOpen={isPopoverVisible}
            onClickOutside={() => setIsPopoverVisible(false)}
            onMouseLeave={() => {
              setIsPopoverVisible(false)
            }}
            isDark
            headerSlot={
              <>
                <Text font='subtitle-2'>Region Breakdown</Text>
                <Text font='body-3'>Select an API to view status details</Text>
              </>
            }
            bodySlot={
              <>
                {regionalHealth.map(({ name, region, status }) => (
                  <div className={styles.region} key={`${name}-${region}`}>
                    <StatusIndicator status={status} className={styles.statusIcon} />
                    <Link
                      navigate={navigate}
                      href={
                        updatedOn
                          ? `/status/${slug}?region=${region}&date=${moment(updatedOn).format(
                              'YYYY-MM-DD'
                            )}`
                          : `/status/${slug}`
                      }
                    >
                      {MAPPED_REGIONS[region] || region}
                    </Link>
                  </div>
                ))}
              </>
            }
          >
            {statusText}
          </Popover>
        ) : (
          statusText
        )}
      </div>
      {updatedOn && (
        <Text className={styles.lastUpdated} font='legal-1' data-testid='status-date'>
          Last Updated {moment(updatedOn).fromNow()}
        </Text>
      )}

      <div className={styles.uptime}>
        <Text className={styles.uptimeDetail} font='legal-1' as='div'>
          Uptime (last 90 days): <UptimeDetails regions={uptime?.current} innerKey={'current'} />
        </Text>
        <Text className={styles.uptimeDetail} font='legal-1' as='div'>
          Previous quarter uptime:{' '}
          <UptimeDetails
            regions={uptime?.quarters?.[uptime.quarters?.length - 1]?.regions || []}
            innerKey={'quarter'}
          />
        </Text>
      </div>
    </div>
  )
}
