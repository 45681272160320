import { Link } from '../components/Link'
import { TEXT_LABELS } from './consts'
import { Button } from '@nike/eds'

interface StepsProps {
  slug: string
  navigate?: (href: string) => void
}
export const Steps = ({ slug, navigate }: StepsProps): JSX.Element => (
  <Link navigate={navigate} href={`/${slug}?steps=true`}>
    <Button tabIndex={-1} size='small'>
      {TEXT_LABELS.accessDetails}
    </Button>
  </Link>
)
