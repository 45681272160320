import { Control } from 'react-hook-form'

export interface Option<T> {
  value: T
  label: string
}

export type FieldValue = string | string[] | Option<string>[] | Option<string>

export interface Fields {
  [index: string]: FieldValue
}

export interface Field<T> {
  [index: string]: T
}

export interface RenderFieldProps {
  config: ControlInput<ControlTypes>
  control: Control<Fields>
  accessToken?: string
}

export enum ControlTypes {
  SINGLE_LINE = 'text: single-line',
  MULTI_LINE = 'text: multi-line',
  NUMBER = 'text: numerical',
  EMAIL = 'text: email',
  URL = 'text: url',
  MULTI_EMAIL = 'select: multi-email',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  TOGGLE = 'toggle',
  PRIORITY = 'priority',
  AD_GROUP = 'ad groups select',
}

export type SelectOptionInput = {
  label: string
  value: string
}

export type ControlInput<T> = {
  defaultValue?: string
  options: Array<SelectOptionInput>
  placeholder?: string
  required: boolean
  subtitle?: string
  title: string
  type: T
}

export type FormInput<T> = {
  controls: ControlInput<T>[]
}

export enum Priority {
  CRITICAL = 'CRITICAL',
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
  NORMAL = 'NORMAL',
}
