import { MarkdownComponents } from '../../components/MarkdownComponents'
import ReactMarkdown from 'react-markdown'
import type { ReleaseNote } from 'util/api/pc'

export const ReleaseNoteMarkdown = ({ releaseNote }: { releaseNote?: ReleaseNote }) =>
  releaseNote ? (
    // PC-MARKDOWN comes from console-integrated-platform. It applies md styles consistently.
    <ReactMarkdown className={'PC-MARKDOWN'} components={MarkdownComponents}>
      {releaseNote.markdown}
    </ReactMarkdown>
  ) : null
