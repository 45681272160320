import styles from '../homePage.styl'
import { Link } from '@nike/eds'
import { detect } from 'detect-browser'

interface RoadmapProps {
  roadmapLink?: string
}

export const Roadmap = ({ roadmapLink }: RoadmapProps): JSX.Element => {
  const browser = detect()

  if (browser?.name === 'safari') {
    return (
      <Link href={roadmapLink} rel='noreferrer' target='_blank'>
        View Roadmap
      </Link>
    )
  }

  return <iframe src={roadmapLink} className={styles.roadmap} />
}
