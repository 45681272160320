// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OuervpuHwXZnRPCS9C8G .X2yYLrLb9eJVVvDTZKW2 {
  font: var(--eds-type-body-4);
}
.OuervpuHwXZnRPCS9C8G .sxpjr5qcyZTyJwdRR1EZ {
  color: var(--eds-color-brand-orange);
  width: 50px;
  height: 50px;
}
.OuervpuHwXZnRPCS9C8G .sxpjr5qcyZTyJwdRR1EZ * {
  width: 100%;
  height: 100%;
}
.OuervpuHwXZnRPCS9C8G.XWydukkhetG1LRCxt0y3 {
  text-align: center;
  padding-top: 20%;
}
.OuervpuHwXZnRPCS9C8G.XWydukkhetG1LRCxt0y3 .Cfr_A54V5NZkmxfKhEyw {
  font: var(--eds-type-title-3);
  margin-bottom: 20px;
}
.OuervpuHwXZnRPCS9C8G.XWydukkhetG1LRCxt0y3 .X2yYLrLb9eJVVvDTZKW2 {
  font: var(--eds-type-body-1);
}
.OuervpuHwXZnRPCS9C8G.XWydukkhetG1LRCxt0y3 .sxpjr5qcyZTyJwdRR1EZ {
  width: 100px;
  height: 100px;
}
`, "",{"version":3,"sources":["webpack://./../../components/error-boundary/ErrorBoundary.styl"],"names":[],"mappings":"AACE;EACE,4BAAK;AAAT;AAEE;EACE,oCAAM;EACN,WAAM;EACN,YAAO;AAAX;AAEE;EACE,WAAQ;EACR,YAAQ;AAAZ;AAEE;EACE,kBAAW;EACX,gBAAY;AAAhB;AAEI;EACE,6BAAK;EACL,mBAAc;AAApB;AAEI;EACE,4BAAK;AAAX;AAEI;EACE,YAAM;EACN,aAAO;AAAb","sourcesContent":[".errorWrapper\n  .text\n    font var(--eds-type-body-4)\n\n  .swoosh\n    color var(--eds-color-brand-orange)\n    width 50px\n    height 50px\n\n  .swoosh *\n    width:  100%\n    height: 100%\n\n  &.fullPage\n    text-align center\n    padding-top 20%\n\n    .heading\n      font var(--eds-type-title-3)\n      margin-bottom 20px\n\n    .text\n      font var(--eds-type-body-1)\n\n    .swoosh\n      width 100px\n      height 100px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorWrapper": `OuervpuHwXZnRPCS9C8G`,
	"text": `X2yYLrLb9eJVVvDTZKW2`,
	"swoosh": `sxpjr5qcyZTyJwdRR1EZ`,
	"fullPage": `XWydukkhetG1LRCxt0y3`,
	"heading": `Cfr_A54V5NZkmxfKhEyw`
};
export default ___CSS_LOADER_EXPORT___;
