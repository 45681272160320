// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fMu1jlkhh3j2GHBeKQWi {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/MarkdownComponents/markdownComponents.styl"],"names":[],"mappings":"AAAA;EACE,0BAAgB;AAClB","sourcesContent":[".link \n  text-decoration underline\n    \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `fMu1jlkhh3j2GHBeKQWi`
};
export default ___CSS_LOADER_EXPORT___;
