const REGIONS = [
  {
    label: 'us-east-2',
    value: 'us_east_2',
  },
  {
    label: 'us-east-1',
    value: 'us_east_1',
  },
  {
    label: 'us-west-1',
    value: 'us_west_1',
  },
  {
    label: 'us-west-2',
    value: 'us_west_2',
  },
  {
    label: 'af-south-1',
    value: 'af_south_1',
  },
  {
    label: 'ap-east-1',
    value: 'ap_east_1',
  },
  {
    label: 'ap-south-1',
    value: 'ap_south_1',
  },
  {
    label: 'ap-northeast-3',
    value: 'ap_northeast_3',
  },
  {
    label: 'ap-northeast-2',
    value: 'ap_northeast_2',
  },
  {
    label: 'ap-southeast-1',
    value: 'ap_southeast_1',
  },
  {
    label: 'ap-southeast-2',
    value: 'ap_southeast_2',
  },
  {
    label: 'ap-northeast-1',
    value: 'ap_northeast_1',
  },
  {
    label: 'ca-central-1',
    value: 'ap_central_1',
  },
  {
    label: 'cn-north-1',
    value: 'cn_north_1',
  },
  {
    label: 'cn-northwest-1',
    value: 'cn_northwest_1',
  },
  {
    label: 'eu-central-1',
    value: 'eu_central_1',
  },
  {
    label: 'eu-west-1',
    value: 'eu_west_1',
  },
  {
    label: 'eu-west-2',
    value: 'eu_west_2',
  },
  {
    label: 'eu-west-3',
    value: 'eu_west_3',
  },
  {
    label: 'eu-north-1',
    value: 'eu_north_1',
  },
  {
    label: 'eu-south-1',
    value: 'eu_south_1',
  },
  {
    label: 'me-south-1',
    value: 'me_south_1',
  },
  {
    label: 'sa-east-1',
    value: 'sa_east_1',
  },
  {
    label: 'us-gov-west-1',
    value: 'us_gov_west_1',
  },
  {
    label: 'us-gov-east-1',
    value: 'us_gov_east_1',
  },
  {
    label: 'eastus',
    value: 'eastus',
  },
  {
    label: 'eastus2',
    value: 'eastus2',
  },
  {
    label: 'southcentralus',
    value: 'southcentralus',
  },
  {
    label: 'westus2',
    value: 'westus2',
  },
  {
    label: 'westus3',
    value: 'westus3',
  },
  {
    label: 'australiaeast',
    value: 'australiaeast',
  },
  {
    label: 'southeastasia',
    value: 'southeastasia',
  },
  {
    label: 'northeurope',
    value: 'northeurope',
  },
  {
    label: 'swedencentral',
    value: 'swedencentral',
  },
  {
    label: 'uksouth',
    value: 'uksouth',
  },
  {
    label: 'westeurope',
    value: 'westeurope',
  },
  {
    label: 'centralus',
    value: 'centralus',
  },
  {
    label: 'northcentralu',
    value: 'northcentralu',
  },
  {
    label: 'westus',
    value: 'westus',
  },
  {
    label: 'southafricanorth',
    value: 'southafricanorth',
  },
  {
    label: 'centralindia',
    value: 'centralindia',
  },
  {
    label: 'eastasia',
    value: 'eastasia',
  },
  {
    label: 'japaneast',
    value: 'japaneast',
  },
  {
    label: 'jioindiawest',
    value: 'jioindiawest',
  },
  {
    label: 'koreacentral',
    value: 'koreacentral',
  },
  {
    label: 'canadacentral',
    value: 'canadacentral',
  },
  {
    label: 'francecentral',
    value: 'francecentral',
  },
  {
    label: 'germanywestcentral',
    value: 'germanywestcentral',
  },
  {
    label: 'norwayeast',
    value: 'norwayeast',
  },
  {
    label: 'switzerlandnorth',
    value: 'switzerlandnorth',
  },
  {
    label: 'uaenorth',
    value: 'uaenorth',
  },
  {
    label: 'brazilsouth',
    value: 'brazilsouth',
  },
  {
    label: 'centralusstage',
    value: 'centralusstage',
  },
  {
    label: 'eastusstage',
    value: 'eastusstage',
  },
  {
    label: 'eastus2stage',
    value: 'eastus2stage',
  },
  {
    label: 'northcentralusstage',
    value: 'northcentralusstage',
  },
  {
    label: 'southcentralusstage',
    value: 'southcentralusstage',
  },
  {
    label: 'westusstage',
    value: 'westusstage',
  },
  {
    label: 'westus2stage',
    value: 'westus2stage',
  },
  {
    label: 'asia',
    value: 'asia',
  },
  {
    label: 'asiapacific',
    value: 'asiapacific',
  },
  {
    label: 'australia',
    value: 'australia',
  },
  {
    label: 'brazil',
    value: 'brazil',
  },
  {
    label: 'canada',
    value: 'canada',
  },
  {
    label: 'europe',
    value: 'europe',
  },
  {
    label: 'france',
    value: 'france',
  },
  {
    label: 'germany',
    value: 'germany',
  },
  {
    label: 'global',
    value: 'global',
  },
  {
    label: 'india',
    value: 'india',
  },
  {
    label: 'japan',
    value: 'japan',
  },
  {
    label: 'korea',
    value: 'korea',
  },
  {
    label: 'norway',
    value: 'norway',
  },
  {
    label: 'southafrica',
    value: 'southafrica',
  },
  {
    label: 'switzerland',
    value: 'switzerland',
  },
  {
    label: 'uae',
    value: 'uae',
  },
  {
    label: 'uk',
    value: 'uk',
  },
  {
    label: 'unitedstates',
    value: 'unitedstates',
  },
  {
    label: 'unitedstateseuap',
    value: 'unitedstateseuap',
  },
  {
    label: 'eastasiastage',
    value: 'eastasiastage',
  },
  {
    label: 'southeastasiastage',
    value: 'southeastasiastage',
  },
  {
    label: 'centraluseuap',
    value: 'centraluseuap',
  },
  {
    label: 'eastus2euap',
    value: 'eastus2euap',
  },
  {
    label: 'westcentralus',
    value: 'westcentralus',
  },
  {
    label: 'southafricawest',
    value: 'southafricawest',
  },
  {
    label: 'australiacentral',
    value: 'australiacentral',
  },
  {
    label: 'australiacentral2',
    value: 'australiacentral2',
  },
  {
    label: 'australiasoutheast',
    value: 'australiasoutheast',
  },
  {
    label: 'japanwest',
    value: 'japanwest',
  },
  {
    label: 'jioindiacentral',
    value: 'jioindiacentral',
  },
  {
    label: 'koreasouth',
    value: 'koreasouth',
  },
  {
    label: 'southindia',
    value: 'southindia',
  },
  {
    label: 'westindia',
    value: 'westindia',
  },
  {
    label: 'canadaeast',
    value: 'canadaeast',
  },
  {
    label: 'francesouth',
    value: 'francesouth',
  },
  {
    label: 'germanynorth',
    value: 'germanynorth',
  },
  {
    label: 'norwaywest',
    value: 'norwaywest',
  },
  {
    label: 'switzerlandwest',
    value: 'switzerlandwest',
  },
  {
    label: 'ukwest',
    value: 'ukwest',
  },
  {
    label: 'uaecentral',
    value: 'uaecentral',
  },
  {
    label: 'brazilsoutheast',
    value: 'brazilsoutheast',
  },
  {
    label: 'onprem',
    value: 'onprem',
  },
  {
    label: 'saas',
    value: 'saas',
  },
  {
    label: 'cots',
    value: 'cots',
  },
] as const

export const MAPPED_REGIONS: { [key: string]: string } = REGIONS.reduce(
  (current, region) => ({ ...current, [region.value]: region.label }),
  {}
)
