import { AppConfig, getConfig } from '../../config'
import { AnchorHTMLAttributes, KeyboardEvent, MouseEvent } from 'react'

const isModifiedEvent = (
  event: KeyboardEvent<HTMLAnchorElement> | MouseEvent<HTMLAnchorElement>
): boolean => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  navigate?: (href: string) => void
}

export const Link = ({ navigate, href = '', ...rest }: LinkProps): JSX.Element => {
  const config: AppConfig = getConfig()
  const attributes: Partial<LinkProps> = navigate
    ? {
        href,
        onClick: (
          event: KeyboardEvent<HTMLAnchorElement> | MouseEvent<HTMLAnchorElement>
        ): void => {
          if (!isModifiedEvent(event)) {
            event.preventDefault()
            navigate(href)
          }
        },
      }
    : {
        href: href.startsWith('http') ? href : `${config.platformConsoleUrl}${href}`,
        rel: rest?.rel || 'noreferrer',
        target: rest?.target || '_blank',
      }

  return <a {...attributes} {...rest} />
}
