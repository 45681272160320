import { Link } from '../../components/Link'
import { SupportService } from '../../util/api/pc'
import { Button } from '@nike/eds'

export interface GetSupportProps {
  supportService: SupportService | null
  navigate?: (href: string) => void
  slack: string | null
  name: string
}

export const GetSupport = ({
  name,
  supportService,
  navigate,
  slack,
}: GetSupportProps): JSX.Element => {
  const supportLinkProps =
    supportService === 'SLACK' && !!slack
      ? {
          href: slack.startsWith('http')
            ? slack
            : `https://nikedigital.slack.com/archives/${slack}`,
        }
      : {
          href: `${window.location.origin}/support?techSolution=${encodeURIComponent(name)}`,
          navigate,
        }
  return (
    <Button as={Link} size='small' variant='secondary' {...supportLinkProps}>
      Get Support
    </Button>
  )
}
