import type { SnackOptions } from '../../Snack/Snack'
import styles from './releaseNotes.module.styl'
import { formattedReleaseDate, isNewReleaseNote } from './util'
import { Icon, Link, Text, Tooltip } from '@nike/eds'
import { MouseEvent } from 'react'
import type { ReleaseNote } from 'util/api/pc'

interface ReleaseNoteHeaderProps {
  releaseNote: ReleaseNote
  releaseNoteUrl: (releaseNote?: ReleaseNote) => string
  releaseNotesLastSeen: string
  createSnack: (options: SnackOptions) => void
  setFocusedReleaseNote: (releaseNote: ReleaseNote) => void
}

export const ReleaseNoteHeader = ({
  releaseNote,
  releaseNoteUrl,
  releaseNotesLastSeen,
  createSnack,
  setFocusedReleaseNote,
}: ReleaseNoteHeaderProps): JSX.Element => (
  <div className={styles.releaseNoteHeader}>
    <div>
      <Link
        className={styles.dateReleased}
        font='subtitle-2'
        href={releaseNoteUrl(releaseNote)}
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.preventDefault()
          window.history.pushState(null, document.title, releaseNoteUrl(releaseNote))
          setFocusedReleaseNote(releaseNote)
        }}
        data-external-id='view-release-note'
      >
        {formattedReleaseDate(releaseNote)}
      </Link>
      <Tooltip isDark label='Copy Link' role='tooltip' bodySlot={<Text>Copy Link</Text>}>
        <Link
          className={styles.copyLink}
          onClick={async (e: MouseEvent<HTMLElement>) => {
            e.preventDefault()
            await navigator.clipboard.writeText(releaseNoteUrl(releaseNote))
            createSnack({
              id: 'release-note-link-copied',
              status: 'success',
              message: 'Link copied to clipboard',
            })
          }}
          data-testid='copy-release-note-link'
          data-external-id='copy-release-note-link'
        >
          <Icon name='Link' size='s' />
        </Link>
      </Tooltip>
    </div>
    {isNewReleaseNote(releaseNote.dateReleased ?? '', releaseNotesLastSeen ?? '') && (
      <Text font='body-3' className={styles.newChip}>
        New
      </Text>
    )}
  </div>
)
