import { Field, RenderFieldProps } from './types'
import { validation } from './validation'
import { TextField } from '@nike/eds'
import { Controller, ControllerRenderProps } from 'react-hook-form'

interface FieldProps extends RenderFieldProps {
  isNumber?: boolean
}

export const Text = ({ config, control, isNumber }: FieldProps): JSX.Element => (
  <Controller
    name={config.title}
    control={control}
    defaultValue={config.defaultValue}
    rules={validation(config.type, config.required, config.title)}
    render={({ field, fieldState: { error } }) => (
      <TextField
        id={config.title}
        label={config.title}
        defaultValue={config?.defaultValue}
        placeholder={config?.placeholder}
        hasErrors={!!error}
        subtitle={config?.subtitle}
        errorMessage={error?.message}
        type={isNumber ? 'number' : 'text'}
        {...(field as ControllerRenderProps<Field<string>, string>)}
      />
    )}
  />
)
