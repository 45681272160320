import { Field, RenderFieldProps } from './types'
import { validation } from './validation'
import { TextArea as EdsTextArea } from '@nike/eds'
import { Controller, ControllerRenderProps } from 'react-hook-form'

export const TextArea = ({ config, control }: RenderFieldProps): JSX.Element => (
  <Controller
    name={config.title}
    control={control}
    rules={validation(config.type, config.required, config.title)}
    defaultValue={config.defaultValue}
    render={({ field, fieldState: { error } }) => (
      <EdsTextArea
        rows={6}
        id={config.title}
        label={config.title}
        hasErrors={!!error}
        errorMessage={error?.message}
        defaultValue={config?.defaultValue}
        placeholder={config?.placeholder}
        subtitle={config?.subtitle}
        {...(field as ControllerRenderProps<Field<string>, string>)}
      />
    )}
  />
)
