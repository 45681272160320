import { getConfig } from '../../../config'
import { RenderFieldProps } from './types'
import { adGroupValidation } from './validation'
import { SelectAdGroup } from '@nike/cx-x-product'
import { Controller } from 'react-hook-form'

const AD_GROUPS_ENV = {
  prod: 'prod',
  qa: 'qa',
}

export const AdGroupSelect = ({ config, control, accessToken }: RenderFieldProps): JSX.Element => {
  const { defaultValue, required, subtitle, title } = config
  const { env } = getConfig()

  return (
    <Controller
      name={title}
      control={control}
      rules={{
        validate: adGroupValidation(config),
      }}
      defaultValue={defaultValue || []}
      render={({ field, fieldState: { error } }) => (
        <SelectAdGroup
          id={title}
          label={title}
          subtitle={subtitle}
          hasErrors={!!error}
          errorMessage={error?.message}
          required={required}
          env={env === 'prod' ? AD_GROUPS_ENV.prod : AD_GROUPS_ENV.qa}
          accessToken={accessToken}
          isCreatable={false}
          isUnlimitedSearch
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={field.name}
        />
      )}
    />
  )
}
