import { StatusIndicator } from '../components/StatusIndicator'
import { RequestStatus } from '../util/api/pc'
import { ShowStatusDetailsButton } from './ShowStatusDetailsButton'
import styles from './accessRequest.styl'
import { REQUEST_STATUS, TEXT_LABELS } from './consts'
import { Button, Text, Icon, Tooltip } from '@nike/eds'

interface StatusProps {
  status?: RequestStatus
  requestAccess?(): void
  disclaimer?: string
  isAccessRequest: boolean
  fetchStatus?: () => void
  disableRequesting?: boolean
  disableStatusModal?: boolean
  role?: string
  displayInfo?: boolean
}

export const Status = ({
  status,
  requestAccess,
  disclaimer,
  isAccessRequest,
  fetchStatus,
  disableRequesting,
  disableStatusModal,
  role,
  displayInfo,
}: StatusProps): JSX.Element | null => {
  switch (status) {
    case REQUEST_STATUS.available:
      return disableRequesting ? (
        <div className={styles.accessWrapper}>
          <StatusIndicator status='undetermined' />
          <Text font='body-3' as='span'>
            {TEXT_LABELS.requestAvailable}
          </Text>
        </div>
      ) : (
        <Button tabIndex={-1} size='small' onClick={requestAccess}>
          {TEXT_LABELS.requestAccess}
        </Button>
      )
    case REQUEST_STATUS.pending:
      return (
        <div className={styles.accessWrapper}>
          <ShowStatusDetailsButton disable={disableStatusModal} role={role}>
            <StatusIndicator status='undetermined' />
            <Text font='body-3' as='span' className={styles.statusText}>
              {TEXT_LABELS.requestPending}
            </Text>
          </ShowStatusDetailsButton>
        </div>
      )
    case REQUEST_STATUS.declined:
      return (
        <div className={styles.accessWrapper}>
          <ShowStatusDetailsButton disable={disableStatusModal} role={role}>
            <StatusIndicator status='fail' />
            <Text font='body-3' as='span' className={styles.statusText}>
              {TEXT_LABELS.accessDeclined}
            </Text>
          </ShowStatusDetailsButton>
          {displayInfo && (
            <Tooltip bodySlot='Check your e-mail for more information' isDark>
              <span className={styles.tooltipIcon}>
                <Icon
                  size='s'
                  backgroundShape='circle'
                  name='Info'
                  backgroundColor='var(--eds-color-grey-2)'
                />
              </span>
            </Tooltip>
          )}

          {!disableRequesting && (
            <Button
              beforeSlot={<Icon name='Repeat' />}
              tabIndex={-1}
              size='small'
              onClick={requestAccess}
            >
              {TEXT_LABELS.resendRequest}
            </Button>
          )}
        </div>
      )
    case REQUEST_STATUS.failed:
      return (
        <div className={styles.accessWrapper}>
          <ShowStatusDetailsButton disable={disableStatusModal} role={role}>
            <StatusIndicator status='fail' />
            <Text font='body-3' as='span' className={styles.statusText}>
              {isAccessRequest ? TEXT_LABELS.requestFailed : TEXT_LABELS.statusCheckFailed}
            </Text>
          </ShowStatusDetailsButton>

          {!disableRequesting && (
            <Button
              beforeSlot={<Icon name='Repeat' />}
              tabIndex={-1}
              size='small'
              onClick={isAccessRequest ? requestAccess : fetchStatus}
            >
              {TEXT_LABELS.retry}
            </Button>
          )}
        </div>
      )
    case REQUEST_STATUS.approved:
      return (
        <div className={styles.accessWrapper}>
          <StatusIndicator status='pass' />
          <Text font='body-3' as='span'>
            {TEXT_LABELS.accessGranted}
          </Text>
          {disclaimer && (
            <Tooltip bodySlot={disclaimer} isDark>
              <span className={styles.tooltipIcon}>
                <Icon
                  size='s'
                  backgroundShape='circle'
                  name='Info'
                  backgroundColor='var(--eds-color-grey-2)'
                />
              </span>
            </Tooltip>
          )}
        </div>
      )
    default:
      return null
  }
}
