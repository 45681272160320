import { RequestStatus, UserAccessSettingsV2 } from '../util/api/pc'
import { Dispatch, MutableRefObject, RefObject, SetStateAction, createContext } from 'react'

export interface Statuses {
  idLocker?: Record<string, RequestStatus | undefined>
  jira?: RequestStatus
  snow?: RequestStatus
}

export interface UserAccessContextData {
  modalIsVisible: MutableRefObject<boolean>
  statuses: Statuses
  setStatuses: Dispatch<SetStateAction<Statuses>>
  portalRef: RefObject<HTMLDivElement>
  accessMethodsCount: number
}

export const UserAccessContext = createContext<
  (UserAccessSettingsV2 | null) & UserAccessContextData
>({
  portalRef: { current: null },
  modalIsVisible: { current: false },
  statuses: {},
  accessMethodsCount: 0,
  setStatuses: () => {},
})
